<template>
    <form class="form-model" @submit.prevent="submitForm">
        <div class="form-text">
            <label for="firstName">Όνομα</label>
            <input type="text" id="firstName" v-model="form.name" placeholder="Εισάγετε το όνομα σας" required/>
            <InputError :message="errors.name" />
        </div>
        <div class="form-text">
            <label for="lastName">Επώνυμο</label>
            <input type="text" id="lastName" v-model="form.lname" placeholder="Εισάγετε το επώνυμό σας" required/>
            <InputError :message="errors.lname" />
        </div>
        <div class="form-text">
            <label for="phone">Τηλέφωνο</label>
            <input type="tel" id="phone" v-model="form.phone" placeholder="Εισάγετε το τηλέφωνό σας" required/>
            <InputError :message="errors.phone" />
        </div>
        <div class="form-text">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="form.email" placeholder="Εισάγετε το email σας" required/>
            <InputError :message="errors.email" />
        </div>
        <div class="form-model-bottom">
            <div>
                <input type="checkbox" v-model="form.checkBox" :id="`checkbox-${page}`" required/>
                <label :for="`checkbox-${page}`" >Αποδέχομαι την επικοινωνία με την AutoBesikos</label>
            </div>
            <InputError :message="errors.checkbox" />
            <div>
                <Button @click="submitForm" inline submit :processing="processing">Αποστολή</Button>
            </div>
        </div>
    </form>
</template>

<script>
    import { useForm } from "@inertiajs/vue3";
    import Button from "../Button/Button.vue";
    import InputError from "./InputError.vue";

    export default {
        components: {
            Button, InputError,
        },
        props: {
            interestFor: String,
            page: String,
        },
        setup(props, { slots, attrs }) {
            const form = useForm({
                name: '',
                lname: '',
                phone: '',
                email: '',
                page: props.page,
                checkBox: false,
                slug: window.location.pathname.replace(/\//g, '')
            });

            const errors = useForm({
                name: '',
                lname: '',
                phone: '',
                email: '',
                checkbox: '',
            });

            return { form, errors };
        },
        data() {
            return {
                processing: false,
                checkBoxMessage: 'Πρέπει να αποδεχτείς την επικοινωνία με την AutoBesikos',
            }
        },
        methods: {
            submitForm() { 
                if ( this.form.checkBox ) {
                    this.processing = true;
                    axios.post('/forms/page',
                        this.form
                    ).then((response) => {
                        // Clear values
                        this.form.reset();
                        this.errors.reset();
                        this.processing = false;
                        this.$helper.getFormMessage(true, this.$global.message, true, 'Η φόρμα στάλθηκε', 'green', response, this.$global.current_pathname);
                    }).catch((error) => {
                        const getErrors = Object.entries(error.response.data.errors);
                        this.errors.reset();
                        this.processing = false;
                        if (getErrors.length > 0) {
                            getErrors.map((e) => { this.errors[e[0]] = e[1][0]; })
                        } else {
                            this.$helper.getFormMessage(false, this.$global.message, true, 'Κάτι πήγε στραβά', 'red', error);
                        }
                    })
                } else {
                    this.errors.checkbox = this.checkBoxMessage;
                }
            },
        },
    }
</script>