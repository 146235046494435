<template>
    <div class="car-warranty-main">
        <h2>Με την <Link href="/warranty-used-car" title="5πλή Εγγύηση της Autobesikos">5πλή Εγγύηση της Autobesikos</Link></h2>
        <h3>Εγγύηση πραγματικών χιλιομέτρων</h3>
        <div class="car-warranty-grid">
            <div class="car-warranty-item" v-for="item in warranty">
                <Link :href="item.href" :title="item.name">{{ item.name }}</Link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        setup(props, { slots, attrs }) {
            const warranty = [
                {name: 'Eγγύηση ατρακάριστου', href: '/warranty-used-car'},
                {name: 'Eγγύηση 1 έτους/20.000 χλμ.', href: '/warranty-used-car'},
                {name: 'Eγγύηση άρτιας τεχνικής κατάστασης', href: '/warranty-used-car'},
                {name: 'Eγγύηση επιστροφής χρημάτων', href: '/warranty-used-car'},
            ]

            return { warranty };
        },
    }
</script> 