<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '25'" :height="height ? height : '16'" viewBox="0 0 25 16">
        <path d="M5.315,9.274A1.006,1.006,0,0,0,5,10.007V21.993a1.012,1.012,0,0,0,.313.735L13.05,16Zm22.372,0a1.008,1.008,0,0,1,.313.735V21.993a1,1,0,0,1-.315.733L19.95,16l7.737-6.728ZM19.2,16.651,26.5,23H6.5l7.3-6.35L16.5,19l2.7-2.35ZM6,8A2,2,0,0,0,4,9.994V22.006A2,2,0,0,0,6,24H27a2,2,0,0,0,2-1.994V9.994A2,2,0,0,0,27,8Zm10.5,9.7L26.5,9H6.5Z" transform="translate(-4 -8)" :stroke="color ? color : '#ff3b00'" fill-rule="evenodd"/>
    </svg>
</template>

<script>
    export default {
        name: "Email",
        props: ["color", "width", "height"],
    }
</script>