<template>
    <div class="disclosure" key="title">
        <div class="disclosure-title no-select" @click="open = !open">
            <p>{{ title }}</p>
            <Plus class="disclosure-icon" :class="open && 'disclosure-icon-open'" />
        </div>
        <div class="disclosure-text" :class="open && 'disclosure-text-open'">
            <p v-if="text" v-html="text"></p>
            <slot v-else />
        </div>
    </div>
</template>

<script>
    import { Plus } from "../../Icons/Icons";

    export default {
        components: {
            Plus
        },
        props: {
            title: String,
            text: String
        },
        data() {
            return {
                open: false,
            }
        }
    }
</script> 