<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '40'" :height="height ? height : '39.75'" viewBox="0 0 40 39.75">
        <g transform="translate(1 1)">
            <path data-name="Path 13013" d="M27,17V7a2,2,0,0,0-2-2H7A2,2,0,0,0,5,7V17" transform="translate(-5 -5)" fill="none" :stroke="color ? color : '#fff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line data-name="Line 571" x2="4" transform="translate(9 3.75)" fill="none" :stroke="color ? color : '#fff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <rect data-name="Rectangle 149715" width="38" height="22" rx="2" transform="translate(0 15.75)" fill="none" :stroke="color ? color : '#fff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line data-name="Line 572" y2="22" transform="translate(8 15.75)" fill="none" :stroke="color ? color : '#fff'" stroke-linejoin="round" stroke-width="2"/>
            <line data-name="Line 573" y2="4" transform="translate(34 24.75)" fill="none" :stroke="color ? color : '#fff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line data-name="Line 574" y2="2" transform="translate(4 25.75)" fill="none" :stroke="color ? color : '#fff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path data-name="Path 13014" d="M39,15V7H32" transform="translate(-5 -5)" fill="none" :stroke="color ? color : '#fff'" stroke-miterlimit="10" stroke-width="2"/>
            <path data-name="Path 13015" d="M35,11l4,4,4-4" transform="translate(-5 -5)" fill="none" :stroke="color ? color : '#fff'" stroke-miterlimit="10" stroke-width="2"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Rotate",
        props: ["color", "width", "height"],
    }
</script>