<template>
    <div v-if="salePrice" class="car-block-price">
        <div v-if="salePrice > 0.01 && salePrice !== price" class="car-block-price-discount">
            <p class="car-block-price-discount-new">{{ salePrice.toLocaleString('el-EL') }}€</p>
            <p class="car-block-price-discount-old">{{ price.toLocaleString('el-EL') }}€</p>
            <p class="car-block-price-vat">{{ without_vat === 0 ? ' ' : 'χωρίς Φ.Π.Α.' }}</p>
        </div>

        <template v-else>
            <p class="car-block-price-main">{{ price.toLocaleString('el-EL') }}€</p>
            <p class="car-block-price-vat">{{ without_vat === 0 ? ' ' : 'χωρίς Φ.Π.Α.' }}</p>
        </template>
    </div>
    <div v-else class="car-block-price">
        <p class="car-block-price-main">{{ price.toLocaleString('el-EL') }}€</p>
        <p class="car-block-price-vat">{{ without_vat === 0 ? ' ' : 'χωρίς Φ.Π.Α.' }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            price: Number,
            salePrice: Number,
            without_vat: Number,
        },
    }
</script> 