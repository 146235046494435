<template>
    <!-- <Cookies v-if="!$global.cookies"/> -->
    <Header :user="$attrs.auth ? $attrs.auth.user : null"/>
    <Message v-if="$global.message.open" />
    <main>
        <slot/>
    </main>
    <Footer />
</template>

<script>
    import Header from './Header.vue';
    import Footer from './Footer.vue';
    // import Cookies from '../Components/Cookies/Cookies.vue';
    import Message from '../Components/Message/Message.vue';
    import { router } from '@inertiajs/vue3';
    import { computed } from "vue";

    export default {
        name: "Layout",
        components: { 
            Header, Footer, Message,
            // Cookies,
        },
        setup() {

            return {  }
        },
        data() {
            return {
                portrait: null,
                landscape: null,
                widthMobile: 991,
                widthTablet: 1200,
            }
        },
        provide() {
            return {
                // reactive global provider
                watchPaymentMethod: computed(() => this.$global.current_url),
            }
        },
        methods: {
            currentURL() {
                this.$global.current_url = window.location.href;
            },
            currentPath() {
                this.$global.current_pathname = window.location.pathname;

                router.on('start', (event) => {
                    this.$global.current_pathname = event.detail.visit.url.pathname;
                })
            },
            paymentMethod(location) {
                let loc = location ? location : this.$global.current_url;
                const login = new RegExp('(\/vehicles\/)(.*?)(\/login)', 'g');
                const checkout = new RegExp('(\/vehicles\/)(.*?)(\/checkout)', 'g');
                const success = new RegExp('(\/success)', 'g');

                if ( loc.match(login) || loc.match(checkout) || loc.match(success) ) { 
                    this.$global.payment_method = true;
                } else {
                    this.$global.payment_method = false;
                }
            },
            popstate() {
                // If the user go back or forward
                this.paymentMethod(window.location.pathname);
            },
            resize() {
                if ( window.innerWidth <= this.widthMobile ) {
                    this.$global.isMobile = true;
                    if ( window.innerHeight > window.innerWidth ){ this.$global.orientation = 'portrait'; } else { this.$global.orientation = 'landscape'; }
                } else { 
                    this.$global.isMobile = false;
                    this.$global.orientation = '';
                };
                if ( window.innerWidth <= this.widthTablet ) { this.$global.isTablet = true; } else { this.$global.isTablet = false; };
            },
            mediaOrientation(event) {
                if ( event.matches ) { this.resize(); }
            },
            startOrientation() {
                if ( window.innerHeight > window.innerWidth && window.innerWidth <= this.widthMobile ){
                    this.$global.orientation = 'portrait';
                } else if ( window.innerHeight < window.innerWidth && window.innerWidth <= this.widthMobile ){
                    this.$global.orientation = 'landscape';
                } else {
                    this.$global.orientation = '';
                }
            },
            handleOrientation(event) {
                if ( event.currentTarget.type.indexOf('landscape') > -1 && window.innerWidth <= this.widthMobile ) {
                    this.$global.orientation = 'landscape';
                } else if ( event.currentTarget.type.indexOf('portrait') > -1 && window.innerWidth <= this.widthMobile ) {
                    this.$global.orientation = 'portrait';
                } else {
                    this.$global.orientation = '';
                }
            },
        },
        created() {
            this.currentURL();
            this.paymentMethod(null);
            this.startOrientation();
        },
        mounted() {
            this.resize();
            this.currentPath();
            window.addEventListener('resize', this.resize);
            screen.orientation.addEventListener('change', this.handleOrientation);
        },
        updated() {
            this.currentURL();
            this.paymentMethod(null);
        },
        unmounted() {
            window.removeEventListener('resize', this.resize);
            window.removeEventListener('popstate', this.popstate);
            screen.orientation.removeEventListener('change', this.handleOrientation);
        }
    }
</script>