<template>
    <svg xmlns="http://www.w3.org/2000/svg" :fill="color ? color : '#FF3B00'" viewBox="0 0 1184.2 1156" :width="width ? width : '24'" :height="height ? height : '24'">
        <path d="M71.8,649l4.9-1.5,174.2-54.7c12.2-3.8,12.2-25.8,0-29.6l-174.2-54.7-4.9-1.5c-4.7-1.5-9.5-2.2-14.1-2.2-9.2,0-18.1,2.8-26,7.9-5.4,3.4-10.3,7.9-14.6,13.2-10.6,13.1-17.2,31.4-17.2,52.1s13.2,53.4,31.8,65.3c7.9,5.1,16.7,7.9,26,7.9s9.3-.7,14.1-2.2ZM33.8,578c0-16.9,13.7-30.7,30.6-30.7s30.7,13.7,30.7,30.7-13.7,30.6-30.7,30.6-30.6-13.7-30.6-30.6Z"/>
        <path d="M536.7,633.4c8.3,10.4,18.8,18.8,30.9,24.7,11.7,5.6,24.7,8.8,38.6,8.8h0c13.8,0,26.9-3.1,38.6-8.8,12.1-5.8,22.6-14.3,30.9-24.7,8.2-10.3,14.2-22.4,17.2-35.6,1.4-6.4,2.2-13,2.2-19.8s-.8-13.4-2.2-19.8c-3-13.2-9-25.4-17.2-35.6-8.3-10.4-18.8-18.9-30.9-24.7-11.7-5.6-24.8-8.8-38.6-8.8h0c-13.8,0-26.9,3.1-38.6,8.8-12.1,5.8-22.6,14.3-30.9,24.7-8.2,10.3-14.2,22.4-17.2,35.6-1.4,6.4-2.2,13-2.2,19.8s.8,13.4,2.2,19.8c3,13.2,9,25.4,17.2,35.6Z"/>
        <path d="M1138.7,353c-29.1-68.8-70.8-130.7-123.9-183.7-53.1-53.1-114.9-94.7-183.7-123.8C759.9,15.3,684.2,0,606.1,0s-153.7,15.3-225,45.4c-68.8,29.1-130.6,70.8-183.7,123.8-53.1,53.1-94.8,114.9-123.9,183.7-18.9,44.8-32,91.2-39.1,138.9,7.5-3,15.3-4.5,23.3-4.5s12.9,1,19.3,3l2.2.7c1.8-10.8,3.9-21.4,6.3-32l380.5,86.8,30.5,7c3.8-16.7,11.4-32.1,21.8-45.1l-24.5-19.5L188.9,245.2c49.8-62.3,113.2-113.2,185.6-148.3l169.3,351.5,13.6,28.2c14.8-7.1,31.3-11.2,48.8-11.2h0V44c82.9,0,161.5,19,231.6,52.9l-182.8,379.7c15.3,7.4,28.6,18.1,39.1,31.3l24.5-19.5,304.9-243.1c49.3,61.7,85.2,134.4,103.3,213.9l-380.5,86.8-30.5,7c1.8,8.1,2.8,16.4,2.8,25s-1,17-2.8,25l30.5,7,380.5,86.8c-18.1,79.5-54.1,152.3-103.3,214l-304.9-243.1-24.5-19.6c-10.5,13.1-23.8,23.8-39.1,31.2l182.9,379.8c-70.1,33.9-148.7,52.9-231.6,52.9v-421.5h0c-17.5,0-34-4-48.8-11.1l-13.6,28.2-169.3,351.6c-72.5-35-135.9-86-185.6-148.3l304.9-243.1,24.5-19.5c-10.4-13-18-28.3-21.8-45.1l-30.5,7-380.5,86.8c-2.4-10.5-4.5-21.2-6.2-32l-2.3.7c-6.3,2-12.8,3-19.3,3s-15.8-1.5-23.3-4.5c7.1,47.7,20.2,94.2,39.1,139,29.1,68.8,70.8,130.6,123.9,183.7,53.1,53.1,114.9,94.8,183.7,123.9,71.3,30.1,147,45.4,225,45.4s153.7-15.3,225-45.4c68.8-29.1,130.6-70.8,183.7-123.9,53.1-53.1,94.7-114.9,123.9-183.7,30.1-71.3,45.4-147,45.4-225s-15.3-153.7-45.4-225Z"/>
    </svg>
</template>

<script>
    export default {
        name: "Spinner",
        props: ["color", "width", "height"],
    }
</script>