<template>
    <div class="submenu-mobile">
        <div class="submenu-mobile-text" @click="activeMenu = !activeMenu">
            <p v-if="text">{{ text }}</p>
            <slot v-else></slot>
            <Arrow class="arrow" color="#fff" :class="activeMenu ? 'arrow-rotate-up' : 'arrow-rotate-down'" />
        </div>
        <div v-if="activeMenu" class="submenu-mobile-item">
            <div v-for="item in submenu" :key="item.href" @click="emitFunction()">
                <Link :class="item.color ? `submenu-mobile-item-${item.color}` : ''" v-if="!item.external" :method="item.href === '/logout' ? 'post' : 'get'" as="button" :href="item.href" >{{ item.name }}</Link>
                <a v-else :href="item.href" >{{ item.name }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { Arrow } from "../../Icons/Icons";
    import { ref } from "vue";

    export default {
        components: {
            Arrow
        },
        props: {
            text: String,
            submenu: Object,
        },
        setup() {
            const activeMenu = ref(false);

            return { activeMenu }
        },
        methods: {
            emitFunction() {
                this.$emit('triggerMenu');
            },
        },
    }
</script>