<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '17.046'" :height="height ? height : '16.113'" viewBox="0 0 17.046 16.113">
        <path d="M35.621,4.692l4.638.667a1.024,1.024,0,0,1,.83.68.993.993,0,0,1-.255,1.032l-3.363,3.216.794,4.613a.993.993,0,0,1-.416.985,1.057,1.057,0,0,1-1.088.072L32.616,13.8l-4.141,2.155a1.063,1.063,0,0,1-1.091-.072.99.99,0,0,1-.413-.985l.794-4.613L24.4,7.07a.994.994,0,0,1-.255-1.032,1.02,1.02,0,0,1,.83-.68l4.634-.667L31.69.528a1.043,1.043,0,0,1,1.856,0Z" transform="translate(-24.095 0.039)" :fill="active ? color ? color : '#ffd230' : '#fff'"/>
    </svg>
</template>

<script>
    export default {
        name: "Star",
        props: {
            color: String,
            width: String,
            height: String,
            active: Boolean
        }
    }
</script>