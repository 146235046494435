<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '25.714'" :height="height ? height : '20'" viewBox="0 0 25.714 20">
        <path id="images-regular" d="M7.143,34.143H22.857a.716.716,0,0,1,.714.714v10a.716.716,0,0,1-.714.714h-.946l-4.585-7.013a1.071,1.071,0,0,0-1.795,0L13.2,42.121l-.554-.754a1.076,1.076,0,0,0-1.732,0L7.839,45.571h-.7a.716.716,0,0,1-.714-.714v-10A.716.716,0,0,1,7.143,34.143Zm-2.857.714v10a2.86,2.86,0,0,0,2.857,2.857H22.857a2.86,2.86,0,0,0,2.857-2.857v-10A2.86,2.86,0,0,0,22.857,32H7.143A2.86,2.86,0,0,0,4.286,34.857ZM2.143,35.929a1.071,1.071,0,1,0-2.143,0v10A6.072,6.072,0,0,0,6.071,52H20.357a1.071,1.071,0,1,0,0-2.143H6.071a3.929,3.929,0,0,1-3.929-3.929ZM11.429,37A1.429,1.429,0,1,0,10,38.429,1.429,1.429,0,0,0,11.429,37Z" transform="translate(0 -32)" :fill="color ? color : '#000'"/>
    </svg>

</template>

<script>
    export default {
        name: "Images",
        props: ["color", "width", "height"],
    }
</script>