<template>
    <footer id="footer" :class="$global.payment_method && 'footer-payment'" class="no-print">
        <div v-if="!$global.payment_method" class="footer">
            <div class="footer-top">
                <div class="footer-row">
                    <LogoFooter />
                    <p>Η AutoBesikos ιδρύθηκε το 1975 και με την κορυφαία εξυπηρέτηση, την αξιοπιστία και τις ολοκληρωμένες υπηρεσίες συνεχίζει με συνέπεια την πορεία ανάπτυξης.</p>
                    <div class="footer-contact">
                        <div class="footer-contact-item">
                            <div>
                                <Phone color="#ff3b00" />
                                <a href="tel:+302108313963">210 831 3963</a> 
                            </div>
                            <div>
                                <Email />
                                <a href="mailto:sales@besikos.gr">sales@besikos.gr</a> 
                            </div>
                        </div>
                        <div class="footer-contact-item">
                            <div>
                                <Phone color="#ff3b00" />
                                <a href="tel:+302108001111">210 800 1111</a> 
                            </div>
                            <div>
                                <Email />
                                <a href="mailto:peugeot@besikos.gr">peugeot@besikos.gr</a> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-row">
                    <div class="footer-row-left">
                        <h3>ΥΠΗΡΕΣΙΕΣ</h3>
                        <div class="footer-links">
                            <a href="/insurance">Ασφάλεια</a>
                            <a href="/auto-loan-calculator">Χρηματοδότηση</a>
                            <a href="/service">Service</a>
                        </div>
                    </div>
                    <div class="footer-row-right">
                        <h3>ΧΡΗΣΙΜΑ LINKS</h3>
                        <div class="footer-links">
                            <a href="/about-us">Ποιοι είμαστε</a>
                            <a href="/contact-us">Επικοινωνία</a>
                            <a href="/blog">Blog</a>
                            <a href="/faq">Συχνές Ερωτήσεις</a>
                        </div>
                    </div>
                </div>
                <!-- <div class="footer-row">
                    <h3>ΕΓΓΡΑΦΗ ΣΤΟ NEWSLETTER</h3>
                    <div class="footer-newsletter">
                        <p>Μείνε συντονισμένος για όλα τα νέα & τις προσφορές μας</p>
                        <form @submit.prevent="submit">
                            <input type="email" name="email-address" id="email-address" autocomplete="email" required class="w-full md:mr-4 h-14 appearance-none rounded-[29px] border border-transparent bg-white py-2 md:px-4 text-base text-gray-900 placeholder-gray-500 focus:border-0" placeholder="email@email.com" />
                            <div>
                                <Button inline submit>Εγγραφή</Button>
                            </div>
                        </form>
                    </div>
                </div> -->
            </div>
            <div class="footer-gap"></div>
            <div class="footer-bottom">
                <div class="footer-bottom-first">
                    <div class="footer-bottom-social">
                        <a href="https://www.facebook.com/autobesikos.gr" target="_blank" rel="noopener">
                            <Facebook width="23" height="23" />
                        </a>
                        <a href="https://www.instagram.com/autobesikos" target="_blank" rel="noopener">
                            <Instagram width="23" height="23" />
                        </a>
                        <a href="https://www.youtube.com/MrBesikos" target="_blank" rel="noopener">
                            <Youtube width="23" height="23" />
                        </a>
                    </div>
                    <div class="footer-bottom-payments">
                        <h3>ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ</h3>
                        <div>
                            <VivaWallet />
                        </div>
                    </div>
                </div>
                <div class="footer-bottom-last">
                    <p>AutoBesikos Copyright © {{ year }}
                        <span>|</span>
                        <a href="/privacy-policy">Πολιτική προστασίας</a>
                        <span>|</span>
                        <a href="/financial-stats">Οικονομικά στοιχεία</a>
                        <br />
                        Design development, Support digital marketing by <a href="https://softly.gr" title="Digital Marketing Softly" target="_blank"><b>Softly</b></a>
                    </p>
                </div>
            </div>
        </div>
        <div v-else class="footer-payment-main">
            <p>AutoBesikos Copyright © {{ year }}<br />Design development, Support digital marketing by <a href="https://softly.gr" title="Digital Marketing Softly" target="_blank"><b>Softly</b></a></p>
            <div>
                <p>Τηλέφωνο επικοινωνίας : <a href="tel:+302108001111">210 800 1111</a></p>
                <Link href="/contact-us">Χρειάζεσαι βοήθεια;</Link>
            </div>
        </div>
    </footer>
</template>

<script>
    import { LogoFooter, Phone, Email, VivaWallet, Facebook, Instagram, Linkedin, Youtube } from "../Icons/Icons";
    import Button from "../Components/Button/Button.vue";

    export default {
        components: {
            LogoFooter, Phone, Email, VivaWallet, Facebook, Instagram, Linkedin, Youtube,
            Button,
        },
        mounted() {
            const thisYear = new Date();
            this.year = thisYear.getFullYear();
        },
        data() {
            return {
                year: 2002,
            }
        },
        setup() {
            const menu = [
                { name: 'Αυτοκίνητα', href: '/vehicles' },
                { name: 'Ποιοι είμαστε', href: '/about-us' },
                { name: 'Υπηρεσίες',
                    submenu: [
                       { name: 'Ασφάλεια', href: '/insurance' },
                       { name: 'Χρηματοδότηση', href: '/auto-loan-calculator' },
                       { name: 'Service', href: '/service' }
                    ]
                },
                { name: 'Blog', href: '/blog' },
                { name: 'Επικοινωνία', href: '/contact-us' },
            ];

            const user = [
                { name: 'Σύνδεση', href: '/login' },
                { name: 'Εγγραφή', href: '/register' }
            ]

            return { menu, user }
        }
    }
</script>