<template>
    <!-- Favorites -->
    <SetFavorites :user="$attrs.auth.user" :cars="$attrs.cars.data" @localFavorites="local_favorites" />
    <RefreshPageFavorites :user="$attrs.auth.user" :favorites_cars="favorites_cars" />
    <!-- End Favorites -->

    <div class="account-page" :class="sidebarIsOpen && 'account-page-open'">
        <UserSidebar @openMenu="SidebarMenu" />
        <div class="account-info">
            <section class="account-info-top">
                <h1>Αγαπημένα</h1>
            </section>
            <section class="account-info-main">
                <div class="account-info-pages">
                    <p v-if="local_favorites_cars.length > 0">Βρέθηκαν {{ local_favorites_cars.length }} αγαπημένα προϊόντα</p>
                    <p v-else>Δεν έχετε ακόμα αγαπημένα προίόντα</p>
                </div>
                <div v-if="favorites_cars.length > 0" class="account-info-pages">
                    <h2>Προς αφαίρεση</h2>
                </div>
                <div v-if="favorites_cars.length > 0" class="account-favorites">
                    <div v-for="car in $attrs.cars.data.filter((data) => favorites_cars.includes(data.id))" :key="car.id+'-deleted'" class="car-block">
                        <Car :car="car" :user="$attrs.auth.user" @favorite_car="updateFavorites" />
                    </div>
                </div>
                <div v-if="favorites_cars.length > 0" class="account-info-pages">
                    <h2 v-if="local_favorites_cars.length === 0">Κανένα αποθηκευμένο</h2>
                    <h2 v-else>Αποθηκευμένα</h2>
                </div>
                <div v-if="local_favorites_cars.length !== 0" class="account-favorites" :class="$global.filtersLoading && 'vehicles-cars-loading'">
                    <div v-for="car in $attrs.cars.data.filter((data) => !favorites_cars.includes(data.id) && local_favorites_cars.includes(data.id) )" :key="car.id" class="car-block">
                        <Car :car="car" :user="$attrs.auth.user" @favorite_car="updateFavorites" />
                    </div>
                </div>
                <div @click="updatePage(true)" v-if="favorites_cars.length > 0" class="account-info-button">
                    <Button inline button :disable="favorites_cars.length === 0">Ενημέρωση αλλαγών</Button>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import UserSidebar from "../../Components/User/UserSidebar.vue";
    import Button from "../../Components/Button/Button.vue";
    import Car from "../../Components/Car/CarBlock.vue";
    import SetFavorites from "../../Components/LocalStorage/SetFavorites.vue";
    import RefreshPageFavorites from "../../Components/LocalStorage/RefreshPageFavorites.vue";
    import { router } from "@inertiajs/vue3";

    export default {
        components: {
            UserSidebar, Button, Car, SetFavorites, RefreshPageFavorites
        },
        props: {
        },
        setup(props, { slots, attrs }) {

            return {  };
        },
        data() {
            return {
                buttonPage: false,
                sidebarIsOpen: false,
                favorites_cars: [],
                local_favorites_cars: window.localStorage.getItem('favorites_cars') ? JSON.parse(window.localStorage.getItem('favorites_cars')).map((item) => { return item.id }) : [],
            }
        },
        provide() {
            return {
                // non reactive global provider
                updateFavorites: 0
            }
        },
        methods: {
            pushSplice(array, id) {
                if ( !array.includes(id) ) {
                    array.push(id);
                } else {
                    const index = array.indexOf(id);
                    if ( index > -1 ) {
                        array.splice(index, 1);
                    }
                }
            },
            updateFavorites(id) {
                // Update the array to call axios
                this.pushSplice(this.favorites_cars, id);
                this.pushSplice(this.local_favorites_cars, id);
            },
            SidebarMenu(value) {
                this.sidebarIsOpen = value;
            },
            async updatePage() {
                if ( this.favorites_cars.length > 0 ) {
                    this.buttonPage = true;

                    const postData = axios.post(`${this.$global.origin}/account/favorites`, { 
                        ids: this.favorites_cars 
                    }).then((response) => {
                        this.$helper.getFormMessage(true, this.$global.message, true, 'Ενημερώθηκαν', 'green', response);
                    }).catch((error) => {
                        this.$helper.getFormMessage(false, this.$global.message, true, 'Υπήρξε πρόβλημα', 'red', error);
                    })

                    // After the post - Reload the page
                    await Promise.all([ postData ]).then(() => {
                        router.get(`${this.$global.origin}/account/favorites`, {})
                    })
                }
            },
            local_favorites(data) {
                this.local_favorites_cars = data;
            }
        },
        beforeUnmount() {
            if ( !this.buttonPage && this.favorites_cars.length > 0  ) {
                axios.post(`${this.$global.origin}/account/favorites`, { ids: this.favorites_cars }).then((data) => { return data });
            }
        }
    }
</script>