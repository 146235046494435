<template>
    <div class="login-main">
        <section class="login">
            <h2>Σύνδεση</h2>
            <form class="form-user" @submit.prevent="loginForm">
                <div class="form-text">
                    <label for="login_email">Email*</label>
                    <input autocomplete="email" required type="email" id="login_email" v-model="login.email" placeholder="autobesikos@gmail.com"/>
                    <InputError :message="$attrs.errors.email"/>
                </div>
                <div class="form-text">
                    <label for="login_password">Password*</label>
                    <Password @password_value="loginPassword" _id="login_password" />
                    <InputError :message="$attrs.errors.password"/>
                </div>
                <div class="form-user-bottom">
                    <div class="form-user-bottom-checkbox">
                        <input type="checkbox" id="login_checkbox"/>
                        <label for="login_checkbox" @click="login.remember = !login.remember">Να με θυμάσαι</label>
                    </div>
                    <div class="form-user-bottom-button">
                        <Button @click="loginForm" inline submit :processing="processing" >Σύνδεση</Button>
                    </div>
                    <div class="form-user-bottom-button">
                        <Button href="/social/google" black outline anchor ><GoogleLogo width="22" height="22" /> Σύνδεση με Google</Button>
                    </div>
                    <div class="form-user-bottom-button">
                        <Button href="/social/facebook" black outline anchor ><Facebook color="#4b7acc" width="22" height="22" /> Σύνδεση με Facebook</Button>
                    </div>
                    <div class="form-user-bottom-pass">
                        <Link href="/forgot-password" title="Ξέχασες τον κωδικό σου?">Ξέχασες τον κωδικό πρόβασης</Link>
                    </div>
                </div>
            </form>
        </section>
        <section class="register">
            <h2>Εγγραφή</h2>
            <form class="form-user" @submit.prevent="registerForm">
                <div class="form-text">
                    <label for="register_name">Username*</label>
                    <input required type="text" id="register_name" v-model="register.name" placeholder="π.χ. AutoBesikos"/>
                </div>
                <div class="form-text">
                    <label for="register_email">Email*</label>
                    <input autocomplete="email" required type="email" id="register_email" v-model="register.email" placeholder="autobesikos@gmail.com"/>
                    <InputError :message="$attrs.errors.email"/>
                </div>
                <div class="form-text">
                    <label for="password_register">Password*</label>
                    <Password @password_value="registerPassword" _id="register_password" />
                </div>
                <div class="form-text">
                    <label for="password_register">Confirmation Password*</label>
                    <Password @password_value="registerPasswordConfirmation" _id="register_password_confirmation" />
                    <InputError :message="message"/>
                </div>
                <div class="form-user-bottom">
                    <div class="form-user-bottom-checkbox">
                        <input type="checkbox" id="register_checkbox"/>
                        <label for="register_checkbox" @click="register.remember = !register.remember">Να με θυμάσαι</label>
                    </div>
                    <div class="form-user-bottom-button">
                        <Button @click="registerForm" black inline submit :processing="processing" >Δημιουργία λογαριασμού</Button>
                    </div>
                    <div class="form-user-bottom-button">
                        <Button href="/social/google" black outline anchor ><GoogleLogo width="22" height="22" /> Εγγραφή με Google</Button>
                    </div>
                    <div class="form-user-bottom-button">
                        <Button href="/social/facebook" black outline anchor ><Facebook color="#4b7acc" width="22" height="22" /> Εγγραφή με Facebook</Button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
    import { useForm, router } from "@inertiajs/vue3";
    import Button from "../../Components/Button/Button.vue";
    import { Facebook, GoogleLogo } from "../../Icons/Icons";
    import Password from "../../Components/Form/Password.vue";
    import InputError from "../../Components/Form/InputError.vue";

    export default {
        components: {
            Button, Password, InputError,
            Facebook, GoogleLogo,
        },
        setup(props, { slots, attrs }) {
            const login = useForm({
                email: '',
                password: '',
                remember: false,
            });
            
            const register = useForm({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                remember: false,
            });

            return { login, register };
        },
        data() {
            return {
                processing: false,
                message: null,
            }
        },
        methods: {
            loginPassword(value) { this.login.password = value; },
            registerPassword(value) { this.register.password = value; },
            registerPasswordConfirmation(value) { this.register.password_confirmation = value; },
            loginForm() {
                this.processing = true;

                if ( window.location.pathname === '/login' ) {
                    this.login.post('/login', {
                        onFinish: () => {
                            this.processing = false;
                            this.login.reset();
                        },
                    })
                } else {
                    const loc = window.location.pathname.replace('login', 'checkout');

                    router.post('/login', {
                        email: this.login.email,
                        password: this.login.password,
                        remember: this.login.remember,
                        redirect_to: loc,
                    }, {
                        onFinish: () => this.processing = false,
                    })
                }
            },
            registerForm() { 
                if ( this.register.password_confirmation === this.register.password ) {
                    this.processing = true;

                    if ( window.location.pathname === '/login' ) {
                        this.register.post('/register', {
                            onFinish: () => {
                                this.processing = false;
                                this.register.reset();
                            },
                        })
                    } else {
                        const loc = window.location.pathname.replace('login', 'checkout');

                        router.post('/register', {
                            name: this.register.name,
                            email: this.register.email,
                            password: this.register.password,
                            password_confirmation: this.register.password_confirmation,
                            remember: this.register.remember,
                            redirect_to: loc,
                        }, {
                            onFinish: () => this.processing = false,
                        })
                    }
                } else {
                    this.message = 'Οι κωδικοί πρέπει να είναι ίδιοι!';
                }
            },
        },
    }
</script>