<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '31.21'" :height="height ? height : '31.21'" viewBox="0 0 31.21 31.21">
        <path d="M6.986,31.219H.516V10.382H6.986ZM3.747,7.54A3.765,3.765,0,1,1,7.495,3.757,3.779,3.779,0,0,1,3.747,7.54ZM31.2,31.219H24.747V21.076c0-2.417-.049-5.517-3.364-5.517-3.364,0-3.88,2.626-3.88,5.343V31.219H11.039V10.382h6.206v2.842h.091A6.8,6.8,0,0,1,23.458,9.86c6.549,0,7.752,4.312,7.752,9.913V31.219Z" transform="translate(0 -0.01)" :fill="color ? color : '#fff'"/>
    </svg>
</template>

<script>
    export default {
        name: "Linkedin",
        props: ["color", "width", "height"],
    }
</script>