<template>
    <Head>
        <title>Επικοινωνήστε μαζί μας - AutoBesikos</title>
        <meta name="description" content="Στην Autobesikos προέχει η εξυπηρέτηση. Επικοινωνήστε μαζί μας και ένας εξειδικευμένος σύμβουλος πωλήσεων θα επικοινωνήσει άμεσα μαζί σας.">
    </Head>

    <div class="contact-page">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα</Link>
            <p>/</p>
            <h1>Επικοινωνία</h1>
        </section>
        <div class="contact-grid">
            <section class="contact-qa">
                <h2>Που θα μας βρείτε;</h2>
                <p>Η AutoBesikos ιδρύθηκε το 1975 και με την κορυφαία εξυπηρέτηση, την αξιοπιστία και τις ολοκληρωμένες υπηρεσίες συνεχίζει με συνέπεια την πορεία ανάπτυξης.</p>
                <div class="contact-qa-grid">
                    <Disclosure title="PEUGEOT BESIKOS">
                        <div class="contact-qa-item">
                            <Pin />
                            <a href="https://goo.gl/maps/2dhnKV6RMMtjzvNa6">Λ. Κηφισίας 323, Κηφισιά</a>
                        </div>
                        <div class="contact-qa-item">
                            <PhoneCall />
                            <p>
                                Τηλ:
                                <a href="tel:+302108001111">210-800-1111</a>
                                &
                                <a href="tel:+302108002222">210-800-2222</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Email width="17" color="#000"/>
                            <p>
                                <a href="mailto:peugeot@besikos.gr">peugeot@besikos.gr</a>
                                &
                                <a href="mailto:peugeot.service@besikos.gr">peugeot.service@besikos.gr</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Calendar />
                            <p>Δευ-Παρ | 9.00 – 21.00 / Σάββατο | 9.00 – 15.00 / Κυριακή | Κλειστά</p>
                        </div>
                    </Disclosure>
                    <Disclosure title="OPEL BESIKOS">
                        <div class="contact-qa-item">
                            <Pin />
                            <a href="https://goo.gl/maps/jrLWzabs2xCYXqkt7">Λ. Αχαρνών 458, Αθήνα</a>
                        </div>
                        <div class="contact-qa-item">
                            <PhoneCall />
                            <p>
                                Τηλ:
                                <a href="tel:+302108313963">210-831-3963</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Email width="17" color="#000"/>
                            <p>
                                <a href="mailto:sales@besikos.gr">sales@besikos.gr</a>
                                &
                                <a href="mailto:opel.sales@besikos.gr">opel.sales@besikos.gr</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Calendar />
                            <p>Δευ-Παρ | 9.00 – 21.00 / Σάββατο | 9.00 – 15.00 / Κυριακή | Κλειστά</p>
                        </div>
                    </Disclosure>
                    <Disclosure title="LEASING PLANET">
                        <div class="contact-qa-item">
                            <Pin />
                            <a href="https://goo.gl/maps/nv3GqVxuTj5RQsKT6">Λεωφόρος Κηφισίας 325, Κηφισιά</a>
                        </div>
                        <div class="contact-qa-item">
                            <PhoneCall />
                            <p>
                                Τηλ:
                                <a href="tel:+302108014111">210-801-4111</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Email width="17" color="#000"/>
                            <p>
                                <a href="mailto:info@leasingplanet.gr">info@leasingplanet.gr</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Calendar />
                            <p>Δευ-Παρ | 9.00 – 21.00 / Σάββατο | 9.00 – 15.00 / Κυριακή | Κλειστά</p>
                        </div>
                    </Disclosure>
                    <Disclosure title="OPEL SERVICE">
                        <div class="contact-qa-item">
                            <Pin />
                            <a href="https://goo.gl/maps/2DyYCfB66c5KTqpF8">Κουρτίδου 161, Αθήνα</a>
                        </div>
                        <div class="contact-qa-item">
                            <PhoneCall />
                            <p>
                                Τηλ:
                                <a href="tel:+302102511800">210-251-1800</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Email width="17" color="#000"/>
                            <p>
                                <a href="mailto:service@besikos.gr">service@besikos.gr</a>
                                &
                                <a href="mailto:opel.service@besikos.gr">opel.service@besikos.gr</a>
                            </p>
                        </div>
                        <div class="contact-qa-item">
                            <Calendar />
                            <p>Δευ-Παρ | 8.30 – 17.00 / Σάββατο & Κυριακή | Κλειστά</p>
                        </div>
                    </Disclosure>
                </div>
            </section>
            <section class="contact-form">
                <h2>Επικοινωνήστε μαζί μας</h2>
                <FormVehicle interestFor="το Peugeot 208 1.2 GT Line" page="Επικοινωνήστε μαζί μας" />
            </section>
        </div>
        <section class="about-us-map">
            <h2>Βρείτε μας στον χάρτη</h2>
            <Map />
        </section>
    </div>
</template>

<script>
    import { PhoneCall, Calendar, Pin, Email } from "../Icons/Icons";
    import Disclosure from "../Components/Disclosure/Disclosure.vue";
    import FormVehicle from "../Components/Form/FormVehicle.vue";
    import Map from "../Components/Map/Map.vue";

    export default {
        components: {
            PhoneCall, Calendar, Pin, Email,
            Disclosure, FormVehicle, Map,
        },
    }
</script>