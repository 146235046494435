<template>
    <div class="form-input-error" :class="color ? `form-input-error-${color}` : ''" v-show="message" :key="message">
        <div>
            <p>{{ message }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            message: String,
            color: String,
        },
    }
</script>