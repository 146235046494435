<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '20'" :height="height ? height : '20'" viewBox="0 0 20 20">
        <g transform="translate(-2 -2)">
            <path data-name="Path 12932" d="M9,21H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H9" fill="none" :stroke="color ? color : '#98a9b5'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path data-name="Path 12933" d="M16,17l5-5L16,7" fill="none" :stroke="color ? color : '#98a9b5'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line data-name="Line 506" x1="12" transform="translate(9 12)" fill="none" :stroke="color ? color : '#98a9b5'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Logout",
        props: ["color", "width", "height"],
    }
</script>