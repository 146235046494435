<template>
    <Head>
        <title>Error {{ status }} - AutoBesikos</title>
        <meta name="description" content="Η σελίδα που αναζητήσατε δεν βρέθηκε. Χρησιμοποιηείστε την αναζήτησή για να βρείτε το επόμενο σας αυτοκίνητο.">
    </Head>

    <div class="error-page">
        <h1>Oops! {{ status }} error</h1>
        <p>Η σελίδα που αναζητήσατε δεν βρέθηκε. Χρησιμοποιηείστε την αναζήτησή για να βρείτε το επόμενο σας αυτοκίνητο.</p>
        <div class="error-search">
            <Search />
        </div>
        <div class="error-bottom">
            <Button inline href="/">Αρχική σελίδα</Button>
            <Button inline black href="/vehicles">Δες αυτοκίνητα</Button>
        </div>
    </div>   
</template>

<script>
    import Search from "../Components/Search/Search.vue";
    import Button from "../Components/Button/Button.vue";

    export default {
        components: {
            Search, Button
        },
        props: {
            status: Number,
        },
    }
</script>