<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '31.228'" :height="height ? height : '31.221'" viewBox="0 0 31.228 31.221">
        <path d="M15.542,39.431a8,8,0,1,0,8,8A7.992,7.992,0,0,0,15.542,39.431Zm0,13.209a5.2,5.2,0,1,1,5.2-5.2A5.213,5.213,0,0,1,15.542,52.639ZM25.741,39.1a1.867,1.867,0,1,1-1.867-1.867A1.863,1.863,0,0,1,25.741,39.1ZM31.043,41a9.239,9.239,0,0,0-2.522-6.542,9.3,9.3,0,0,0-6.542-2.522c-2.578-.146-10.3-.146-12.881,0A9.287,9.287,0,0,0,2.557,34.45,9.27,9.27,0,0,0,.035,40.991c-.146,2.578-.146,10.3,0,12.881a9.239,9.239,0,0,0,2.522,6.542A9.312,9.312,0,0,0,9.1,62.936c2.578.146,10.3.146,12.881,0a9.239,9.239,0,0,0,6.542-2.522,9.3,9.3,0,0,0,2.522-6.542C31.189,51.295,31.189,43.576,31.043,41Zm-3.33,15.64a5.269,5.269,0,0,1-2.968,2.968c-2.055.815-6.932.627-9.2.627s-7.155.181-9.2-.627a5.269,5.269,0,0,1-2.968-2.968c-.815-2.055-.627-6.932-.627-9.2s-.181-7.155.627-9.2a5.269,5.269,0,0,1,2.968-2.968c2.055-.815,6.932-.627,9.2-.627s7.155-.181,9.2.627a5.269,5.269,0,0,1,2.968,2.968c.815,2.055.627,6.932.627,9.2S28.528,54.59,27.713,56.638Z" transform="translate(0.075 -31.825)" :fill="color ? color : '#fff'"/>
    </svg>
</template>

<script>
    export default {
        name: "Instagram",
        props: ["color", "width", "height"],
    }
</script>