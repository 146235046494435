<template></template>

<script>
    import { router } from "@inertiajs/vue3";

    export default {
        created() {
            // If the user is logged in
            if ( this.$attrs.user ) {
                if ( !window.localStorage.getItem('favorites_cars') ) {
                    this.getData();
                }

                if ( window.localStorage.getItem('favorites_cars') ) {
                    this.compareData();
                }
            } else if ( !this.$attrs.user ) { 
                if ( window.localStorage.getItem('favorites_cars') ) {
                    this.clearData();
                }
            }
           
        },
        data() {
            return {
                liveCars: this.$attrs.cars ? this.$attrs.cars.map((car) => { return { id: car.id } }) : [],
            }
        },
        methods: {
            getData() {
                // Get the data - and create the locale storage
                axios.get(`${this.$global.origin}/account/favorites/ids`).then((results) => {
                    const getIDs = results.data.map((item) => { return ({id: item.id}) });
                    window.localStorage.setItem('favorites_cars', JSON.stringify(getIDs));
                    // If the local storage is empty, load the cars to the local array of favorites to display the cars
                    this.emitData(window.localStorage.getItem('favorites_cars'));
                });
            },
            compareData() {
                // Get the data - convert them to array
                const currentLiveCars = Object.values(this.liveCars).map((name) => name.id );
                const currentLocalArrayFavorites = JSON.parse(window.localStorage.getItem('favorites_cars')).map((item) => item.id);
                
                // Get Results
                const compareArrays = currentLocalArrayFavorites.filter((name) => { return !currentLiveCars.includes(name) });

                // Compare the arrays
                if ( compareArrays.length > 0 ) {
                    // If the array is not the same, reload the data
                    router.reload(window.location.href, { only: ['favorites_cars' ]});
                }
            },
            emitData(data) {
                this.$emit('localFavorites', data);
            },
            clearData() {
                // Clear the local storage
                window.localStorage.removeItem('favorites_cars');
            },
        },
    }
</script>
