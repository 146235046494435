<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '17.236'" :height="height ? height : '17.266'" viewBox="0 0 17.236 17.266">
        <path d="M11.809,4A3.748,3.748,0,0,1,14.77,6.959M11.809,1a6.746,6.746,0,0,1,5.959,5.951m-.75,5.981v2.249a1.5,1.5,0,0,1-1.634,1.5,14.833,14.833,0,0,1-6.469-2.3,14.616,14.616,0,0,1-4.5-4.5,14.833,14.833,0,0,1-2.3-6.5A1.5,1.5,0,0,1,3.61,1.75H5.858a1.5,1.5,0,0,1,1.5,1.289,9.624,9.624,0,0,0,.525,2.106,1.5,1.5,0,0,1-.337,1.582l-.952.952a11.993,11.993,0,0,0,4.5,4.5l.952-.952a1.5,1.5,0,0,1,1.582-.337,9.624,9.624,0,0,0,2.106.525,1.5,1.5,0,0,1,1.289,1.522Z" transform="translate(-1.361 -0.172)" fill="none" :stroke="color ? color : '#000'" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>
</template>

<script>
    export default {
        name: "PhoneCall",
        props: ["color", "width", "height"],
    }
</script>