<template>
    <div class="slider-ad-cars">
        <swiper class="slider-ad-cars-carousel" :slides-per-view="1" :space-between="10" :pagination="{type: 'bullets' }" :navigation="true" :modules="modules">
            <swiper-slide v-for="(ad, index) in ads" :key="index">
                <a class="slider-ad-cars-main" :href="ad.href" :target="ad.external ? '_blank' : '_self'">
                    <img :src="ad.img" :alt="`AutoBesikos - ${ad.img_alt}`" width="364" height="335">
                    <div>
                        <div v-if="ad.icon">
                            <img :src="ad.icon" :alt="`AutoBesikos - Vehicles Promo`" width="176" height="auto">
                        </div>
                        <h2>{{ ad.title }}</h2>
                        <p>{{ ad.text }}</p>
                    </div>
                </a>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Pagination, Navigation } from "swiper";
    import "swiper/css";
    import "swiper/css/navigation";
    import "swiper/css/pagination";

    export default {
        components: {
            Swiper, SwiperSlide,
        },
        props: {
            ads: Array
        },
        setup() {
            return { modules: [Pagination, Navigation] }
        },
    }
</script>