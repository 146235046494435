<template>
    <section class="reset-password">
        <h1>Ανάκτηση κωδικού</h1>
        <form class="form-user" @submit.prevent="submitForm">
            <div class="form-text">
                <label for="new_password">Νέος κωδικός*</label>
                <Password @password_value="getPassword" _id="new_password" />
            </div>
            <div class="form-text">
                <label for="nanew_confirmation_passwordme">Επαλήθευση Κωδικού*</label>
                <Password @password_value="getConfirmationPassword" _id="new_confirmation_password" />
                <InputError :message="message"/>
            </div>
            <div class="form-user-bottom-button">
                <Button @click="submitForm" inline submit>Αλλαγή κωδικού</Button>
            </div>
        </form>
    </section>
</template>

<script>
    import Button from "../../Components/Button/Button.vue";
    import { useForm } from "@inertiajs/vue3";
    import InputError from "../../Components/Form/InputError.vue";
    import Password from "../../Components/Form/Password.vue";

    export default {
        components: {
            Button, InputError, Password
        },
        props: {
            email: String,
            token: String,
        },
        setup(props) {
            const form = useForm({
                email: props.email,
                token: props.token,
                password: '',
                password_confirmation: '',
            });

            return { form }
        },
        data() {
            return {
                message: null,
            }
        },
        methods: {
            getPassword(value) { this.form.password = value; },
            getConfirmationPassword(value) { this.form.password_confirmation = value; },
            submitForm() {
                if ( this.form.password === this.form.password_confirmation ) {
                    this.form.post('/reset-password', {
                        onFinish: () => this.form.reset(),
                    })
                } else {
                    this.message = 'Οι κωδικοί πρέπει να είναι ίδιοι!';
                }
            },
        }
    }
</script>