<template>
    <Head>
        <title>AutoBesikos - {{ $attrs.blog.data.title }}</title>
        <meta name="description" :content="$attrs.blog.data.meta_description">
    </Head>

    <div class="blog-page">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα <span>/</span></Link>
            <Link href="/blog">Blog</Link>
            <p>/</p>
            <h1>{{ $attrs.blog.data.title }}</h1>
        </section>
        <div class="blog-main">
            <section class="blog-single">
                <div class="blog-single-top">
                    <img :src="$attrs.blog.data.image.original_url" :alt="`AutoBesikos - ${$attrs.blog.data.slug}`" width="952" height="498">
                    <h2>{{ $attrs.blog.data.title }}</h2>
                </div>
                <div class="blog-single-content" v-html="$attrs.blog.data.content"/>
            </section>
            <section class="blog-more">
                <h2>Διαβάστε επίσης</h2>
                <div class="blog-more-content">
                    <Link class="blog-more-item" v-for="(item, index) in $attrs.latestBlogs.data" :key="index" :href="item.slug">
                        <img :src="item.image.original_url" :alt="`AutoBesikos - ${item.img_alt}`" width="164" height="139">
                        <div>
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.meta_description }}</p>
                            <p>{{ $helper.convertTimeToMonth(item.created_at) }}</p>
                        </div>
                    </Link>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Pagination from "../../Components/Pagination/Pagination.vue";

    export default {
        components: { 
            Pagination
        },
        setup() {
            return {  }
        },
        data() {
            return {
            }
        },
        methods: {
        },
    }
</script>