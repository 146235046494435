<template>
    <aside class="account-sidebar" :class="menu && 'account-sidebar-open'">
        <div class="account-sidebar-top">
            <div class="account-sidebar-menu" @click="openMenu">
                <ListMenu width="28" height="28" :color="menu ? '#000' : '#98A9B5'" />
            </div>
            <Link class="account-sidebar-link" v-for="(info, index) in data" :key="info.link" :href="info.link" :title="info.text">
                <User v-if="index === 1 - 1" width="20" height="20" :color="active === 1 ? '#000' : '#98A9B5'" />
                <Cart v-if="index === 2 - 1" width="20" height="20" :color="active === 2 ? '#000' : '#98A9B5'" />
                <Unlock v-if="index === 3 - 1" width="20" height="20" :color="active === 3 ? '#000' : '#98A9B5'" />
                <Heart v-if="index === 4 - 1" :stroke="true" width="20" height="20" :color="active === 4 ? '#000' : '#98A9B5'" />
                <p :class="active === index + 1 && 'account-sidebar-active'">{{ info.text }}</p>
            </Link>
        </div>
        <Link class="account-sidebar-link account-sidebar-bottom" method="post"  as="button"  href="/logout" title="Αποσύνδεση">
            <Logout width="20" height="20" color="#98A9B5" />
            <p>Αποσύνδεση</p>
        </Link>
    </aside>
</template>

<script>
    import { ListMenu, User, Logout, Cart, Unlock, Heart } from "../../Icons/Icons";

    export default {
        components: {
            User, Logout, Cart, Unlock, Heart, ListMenu
        },
        setup(props, { slots, attrs }) {
            const data = [
                { link: '/account', text: 'Τα στοιχεία μου' },
                { link: '/account/orders', text: 'Ιστορικό κρατήσεων' },
                { link: '/account/password', text: 'Αλλαγή κωδικού πρόσβασης' },
                { link: '/account/favorites', text: 'Τα αγαπημένα μου' },
            ]

            return { data };
        },
        data() {
            return {
                active: 1,
                menu: false,
            }
        },
        methods: {
            activeLink(){
                if ( window.location.pathname === '/account' ) { this.active = 1; }
                if ( window.location.pathname === '/account/orders' ) { this.active = 2; }
                if ( window.location.pathname === '/account/password' ) { this.active = 3; }
                if ( window.location.pathname === '/account/favorites' ) { this.active = 4; }
            },
            openMenu() {
                this.menu = !this.menu;
                this.$emit('openMenu', this.menu);
            },
            stickyNav() {
                const stick = window.pageYOffset || document.documentElement.scrollTop || window.scrollTop;
                const ParentElement = document.querySelector(".account-page");
                const childElement = document.querySelector(".account-sidebar");
                const menuHeight = document.querySelector(".header").offsetHeight;

                let parentElementTop = ParentElement.offsetTop;
                let currentHeight = stick + menuHeight + 30;
                let parentElementHeight = ParentElement.offsetTop + ParentElement.offsetHeight;
                let childElementHeight = childElement.offsetHeight;
                
                if ( currentHeight >= parentElementHeight - childElementHeight ) {
                    childElement.classList.add("account-sidebar-sticky");
                } else {
                    childElement.classList.remove("account-sidebar-sticky");
                }
            },
        },
        created() {
            this.activeLink();
        },
        mounted() {
            window.addEventListener("scroll", this.stickyNav);
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.stickyNav);
        },
    }
</script> 