<template>
    <div :class="`cart-info-${className}`">
        <h2>{{ title }}</h2>
        <div class="cart-info-main">
            <div class="cart-info-text">
                <p>Όνομα</p>
                <p>{{ data.name }}</p>
            </div>
            <div class="cart-info-text">
                <p>Επώνυμο</p>
                <p>{{ data.lastname }}</p>
            </div>
            <div class="cart-info-text">
                <p>Email</p>
                <p>{{ data.email }}</p>
            </div>
            <div class="cart-info-text">
                <p>Τηλέφωνο</p>
                <p>{{ data.tel }}</p>
            </div>
            <div class="cart-info-text">
                <p>Νομός</p>
                <p>{{ data.county }}</p>
            </div>
            <div class="cart-info-text">
                <p>Χώρα</p>
                <p>{{ data.country }}</p>
            </div>
            <div class="cart-info-text">
                <p>Πόλη / Περιοχή</p>
                <p>{{ data.town }}</p>
            </div>
            <div class="cart-info-text">
                <p>Τ.Κ.</p>
                <p>{{ data.postalcode }}</p>
            </div>
        </div>
        <div v-if="data.text" class="cart-info-bottom">
            <p>Σχόλια</p>
            <p>{{ data.text }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            className: String,
            title: String,
            data: Object
        },
    }
</script> 