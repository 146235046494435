@ -1,53 +0,0 @@
<template>
    <Head>
        <title>AutoBesikos - Οικονομικά στοιχεία</title>
        <meta name="description" content="Σε αυτή την ενότητα δημοσιοποιούμε όλα τα οικονομικά δεδομένα τα οποία μπορεί να αφορούν την πελατειακή μας βάση, συνεργάτες μας, αλλά και κάθε ενδιαφερόμενο">
    </Head>

    <div class="single-page">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα</Link>
            <p>/</p>
            <h1>Οικονομικά στοιχεία</h1>
        </section>
        <section>
            <h2>Δημοσιοποίηση Οικονομικών Δεδομένων Της AutoBesikos Α.Ε.</h2>
            <p>Σε αυτή την ενότητα δημοσιοποιούμε όλα τα οικονομικά δεδομένα τα οποία μπορεί να αφορούν την πελατειακή μας βάση, συνεργάτες μας, εμπλεκόμενα μέρη αλλά και κάθε ενδιαφερόμενο.</p>
            <br>
            <p>Απλά κάντε κλικ σε κάθε μία από τις καταστάσεις που σας ενδιαφέρουν και κατεβάστε το αρχείο σε μορφή PDF.</p>
        </section>
        <section class="single-finance">
            <Disclosure v-for="(item, index) in financial" :key="index" :title="item.title">
                <a :href="item.download" download>Download PDF</a>
            </Disclosure>
        </section>
        <section>
            <p>Για οποιαδήποτε άλλη πληροφορία χρειαστείτε, μπορείτε να επικοινωνήσετε μαζί μας <Link href="/contact-us">εδώ</LInk>.</p>
        </section>
    </div>
</template>

<script>
    import Disclosure from "../Components/Disclosure/Disclosure.vue";

    export default {
        components: {
            Disclosure
        },
        setup() {
            const financial = [
                { title: 'ΧΡΗΜΑΤΟΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 2022', download: '/pdf/oikonomikes-katastasis-2022.pdf' },
                { title: 'ΧΡΗΜΑΤΟΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 2021', download: '/pdf/oikonomikes-katastasis-2021.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2020', download: '/pdf/oikonomikes-katastasis-2020.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2019', download: '/pdf/oikonomikes-katastasis-2019.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2018', download: '/pdf/oikonomikes-katastasis-2018.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2017', download: '/pdf/oikonomikes-katastasis-2017.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2016', download: '/pdf/oikonomikes-katastasis-2016.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2015', download: '/pdf/oikonomikes-katastasis-2015.pdf' },
                { title: 'ΙΣΟΛΟΓΙΣΜΟΣ 2013-2014', download: '/pdf/oikonomikes-katastasis-2014.pdf' },
            ]

            return { financial }
        }
    }
</script>