<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '18.51'" :height="height ? height : '17.464'" :viewBox="stroke ? '-1 0 20 17' : '0 0 18.51 17.464'">
        <path d="M17.708,4.454a4.375,4.375,0,0,0-6.517,0l-.888.957-.888-.957a4.376,4.376,0,0,0-6.517,0,5.246,5.246,0,0,0,0,7.026l.888.957L10.3,19.462l6.517-7.026.888-.957a5.245,5.245,0,0,0,0-7.026Z" transform="translate(-1.049 -2.498)" :fill="favorite ? '#FF3B00' : 'none'" :stroke="color ? color : '#fff'" stroke-linecap="round" stroke-linejoin="round" :stroke-width="stroke ? '2' : '1'"/>
    </svg>
</template>

<script>
    export default {
        name: "Heart",
        props: ["color", "width", "height", "stroke", "favorite"],
    }
</script>