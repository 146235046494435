<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  :stroke="color ? color : 'currentColor'" :width="width ? width : '24'" :height="height ? height : '24'">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
</template>

<script>
    export default {
        name: "Arrow",
        props: ["color", "width", "height"],
    }
</script>