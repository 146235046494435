<template>
    <div class="account-page" :class="sidebarIsOpen && 'account-page-open'">
        <UserSidebar @openMenu="SidebarMenu" />
        <div class="account-info">
            <section class="account-info-top">
                <h1>Ιστορικό κρατήσεων</h1>
            </section>
            <section class="account-info-main">
                <div class="account-info-pages">
                    <p>Βρέθηκαν {{ orders.total }} παραγγελίες</p>
                    <p>|</p>
                    <p>Σελίδα {{ orders.current_page }} από {{ orders.last_page }}</p>
                </div>
            </section>
            <section class="account-info-orders">
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">Μοντέλο</th>
                            <th>Κωδικός</th>
                            <th>Κατάσταση</th>
                            <th>Ημερομηνία</th>
                            <th>Ποσό</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in modOrders" :key="order.id">
                            <td><img :src="order.image" :alt="`Car - ${order.model}`" width="67" height="45" /></td>
                            <td><p>{{ order.make }} {{ order.model }}</p></td>
                            <td><p>{{ order.id }}</p></td>
                            <td><p>{{ order.status }}</p></td>
                            <td><p>{{ order.date }}</p></td>
                            <td><p>{{ order.amount }}€</p></td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section class="account-pagination">
                <Pagination :pages="orders.links" />
            </section>
        </div>
    </div>
</template>

<script>
    import UserSidebar from "../../Components/User/UserSidebar.vue";
    import Button from "../../Components/Button/Button.vue";
    import Pagination from "../../Components/Pagination/Pagination.vue";
    
    export default {
        components: {
            UserSidebar, Button, Pagination
        },
        props: {
            orders: Object
        },
        setup(props, { slots, attrs }) {
            const modOrders = props.orders.data.map((order) => {
                const timestamp = new Date(order.created_at);
                const carDate = timestamp.getDate()+'/'+(timestamp.getMonth()+1)+'/'+timestamp.getFullYear();

                return {
                    image: order.car_details.feature_image ? order.car_details.feature_image : '/images/no_image_v2.png',
                    make: order.car_details.make,
                    model: order.car_details.model,
                    id: order.id,
                    status: order.status,
                    date: carDate,
                    amount: order.amount
                }
            })

            return { modOrders };
        },
        data() {
            return {
                sidebarIsOpen: false,
            }
        },
        methods: {
            SidebarMenu(value) {
                this.sidebarIsOpen = value;
            }
        }
    }
</script>