<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '72'" :height="height ? height : '72'" viewBox="0 0 72 72">
        <g data-name="Group 20258" transform="translate(7226 -2576)">
            <rect data-name="bg active" width="72" height="72" rx="36" transform="translate(-7226 2576)" fill="#fff" opacity="0.2"/>
            <rect data-name="bg active" width="54" height="54" rx="27" transform="translate(-7217 2585)" fill="#fff"/>
            <path data-name="Polygon 1" d="M10.5,0,21,18H0Z" transform="translate(-7178.5 2601.5) rotate(90)" :fill="color ? color : '#f73c00'"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Play",
        props: ["color", "width", "height"],
    }
</script>