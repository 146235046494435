<template></template>

<script>
    export default {
        created() {
            if ( this.$attrs.user ) {
                window.addEventListener('visibilitychange', this.visibilityChange);
                window.addEventListener('beforeunload', this.beforeUnload);
            }
        },
        data() {
            return {
            }
        },
        emits: ['postChange'],
        methods: {
            beforeUnload() {
                if ( this.$attrs.favorites_cars.length > 0 && this.$attrs.favorites_cars_trigger ) {
                    window.removeEventListener('visibilitychange', this.visibilityChange);

                    axios.post(`${this.$global.origin}/account/favorites`, { ids: this.$attrs.favorites_cars  }).then((data) => { return data });
                }
            },
            async visibilityChange() {
                if ( this.$attrs.favorites_cars.length > 0 && this.$attrs.favorites_cars_trigger && document.visibilityState === 'hidden') {
                    const postData = axios.post(`${this.$global.origin}/account/favorites`, { ids: this.$attrs.favorites_cars  }).then((data) => { return data });

                    // After the post - Reload the page
                    await Promise.all([ postData ]).then(() => {
                        // Run only once
                        this.emitFunction(false);
                    })
                }
            },
            emitFunction(value) {
                this.$emit('postChange', value);
            }
        },
        unmounted() {
            if ( this.$attrs.user ) {
                window.removeEventListener('visibilitychange', this.visibilityChange);
                window.removeEventListener('beforeunload', this.beforeUnload);
            }
        }
    }
</script>