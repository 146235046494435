<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '21.39'" :height="height ? height : '21.43'" viewBox="0 0 21.39 21.43">
        <path d="M22,16.92v3a2,2,0,0,1-2.18,2,19.79,19.79,0,0,1-8.63-3.07,19.5,19.5,0,0,1-6-6A19.79,19.79,0,0,1,2.12,4.18,2,2,0,0,1,4.11,2h3a2,2,0,0,1,2,1.72,12.84,12.84,0,0,0,.7,2.81,2,2,0,0,1-.45,2.11L8.09,9.91a16,16,0,0,0,6,6l1.27-1.27a2,2,0,0,1,2.11-.45,12.84,12.84,0,0,0,2.81.7A2,2,0,0,1,22,16.92Z" transform="translate(-1.361 -1.25)" fill="none" :stroke="color ? color : '#000'" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>
</template>

<script>
    export default {
        name: "Phone",
        props: ["color", "width", "height"],
    }
</script>