<template>
    <Head>
        <title>AutoBesikos - Πολιτική Δεδομένων</title>
        <meta name="description" content="Πολιτική Απορρήτου: Διαβάστε τις λεπτομέρειες για την προστασία των προσωπικών σας δεδομένων και την ασφάλεια στη χρήση της ιστοσελίδας μας.">
    </Head>

    <div class="single-page">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα</Link>
            <p>/</p>
            <h1>Πολιτική Δεδομένων</h1>
        </section>
        <section class="privacy-policy">
            <p>
                Στην AutoBesikos.gr κατανοούμε ότι το απόρρητο των δεδομένων είναι ένα πολύ σημαντικό ζήτημα. Η AutoBesikos.gr έχει δεσμευτεί να προστατεύει το απόρρητο των προσωπικών σας στοιχείων κάθε φορά που πραγματοποιείτε μια αγορά, και θεωρεί την ασφάλεια των στοιχείων των πελατών ζήτημα μεγάλης σημασίας. Η παρούσα πολιτική εξηγεί πως χρησιμοποιούμε τα στοιχεία των πελατών και πως προστατεύουμε το απόρρητό σας. Παρακαλούμε διαβάστε την προσεκτικά. Με την επίσκεψή σας στην ιστοσελίδα μας, αποδέχεστε τις πρακτικές που περιγράφονται στην παρούσα Πολιτική Προστασίας Προσωπικών Δεδομένων.
            </p>
            <h2>Τι είδους πληροφορίες συλλέγουμε γενικά</h2>
            <p>
                Λαμβάνουμε και συλλέγουμε οποιαδήποτε πληροφορία μας παρέχετε. Αυτό περιλαμβάνει και πληροφορίες ταυτοποίησης («Προσωπικά Στοιχεία»), όπως όνομα και επίθετο, αριθμό τηλεφώνου και ηλεκτρονική διεύθυνση. Μπορείτε να επιλέξετε να μην μας παρέχετε αυτές τις πληροφορίες ωστόσο, πρέπει να γνωρίζετε πως κάποιες πληροφορίες είναι απαραίτητες για να ολοκληρώσετε ή να διαχειριστείτε μια παραγγελία ή για να πάρετε μέρος σε διαγωνισμούς ή έρευνες.
            </p>
            <h2>Στοιχεία πελατών</h2>
            <p>
                Όταν καταχωρείτε την ηλεκτρονική σας διεύθυνση μέσω της AutoBesikos.gr, συλλέγουμε τις καταναλωτικές σας προτιμήσεις. Το προφίλ σας ενημερώνεται και εμπλουτίζεται κάθε φορά που χρησιμοποιείτε την AutoBesikos.gr.
            </p>
            <h2>Αυτόματη συλλογή πληροφοριών</h2>
            <p>
                Όταν επισκέπτεστε την ιστοσελίδα μας AutoBesikos.gr, συλλέγουμε αυτόματα συγκεκριμένες πληροφορίες από τον υπολογιστή σας. Για παράδειγμα, συλλέγουμε πληροφορίες σχετικά με το πρόγραμμα περιήγησης που χρησιμοποιείτε (π.χ. Internet Explorer, Mozilla Firefox, κλπ.) και την διεύθυνση IP. Μπορεί επίσης να συλλέξουμε πληροφορίες σχετικά με τη δραστηριότητα σας στην ιστοσελίδα μας, όπως πληροφορίες σχετικά με προϊόντα που αναζητήσατε ή αγορές που πραγματοποιήσατε. Ο κύριος στόχος συλλογής αυτών των αυτόματων πληροφοριών είναι η συνεχής βελτίωση της εμπειρίας του χρήστη μέσα στην ιστοσελίδα μας.
            </p>
            <h2>Πολιτική Cookies</h2>
            <p>
                Όταν επισκέπτεστε την σελίδα AutoBesikos.gr, αποθηκεύουμε cookies στον υπολογιστή σας. Τα cookies είναι μικρά αρχεία ανώνυμων δεδομένων κειμένου, τα οποία αποθηκεύονται από το πρόγραμμα περιήγησης στο σκληρό δίσκο του υπολογιστή σας. Επιτρέπουν στην AutoBesikos.gr να θυμάται τις προτιμήσεις σας με βάση την τελευταία σας επίσκεψη. Σημειώστε πως όλες οι πληροφορίες που περιέχονται στα cookies είναι κρυπτογραφημένες για λόγους ασφαλείας. Μπορείτε πάντα να επιλέξετε να μην λαμβάνετε cookies από την AutoBesikos.gr, αλλά σε αυτή την περίπτωση μπορεί να μην έχετε πρόσβαση και να μην μπορείτε να χρησιμοποιήσετε κάποιες από τις λειτουργίες της ιστοσελίδας ή να μην καταφέρετε να ολοκληρώσετε με επιτυχία μια κράτηση. Με την τελευταία αναβάθμιση της ιστοσελίδας μας, μπορείτε να επιλέξετε ποια δεδομένα θα μοιράζεστε με εμάς και να διαχειριστείτε τις προτιμήσεις σας σχετικά με τις προτιμήσεις σας και την επικοινωνία και ενδεχόμενες προωθητικές ενέργειες.
            </p>
            <h2>Γενικά Cookies</h2>
            <p>
                Τα cookies είναι μικρά αρχεία κειμένου που χρησιμοποιούνται από τους δικτυακούς τόπους για να κάνουν την εμπειρία του χρήστη πιο αποτελεσματική. Ο νόμος αναφέρει ότι μπορούμε να αποθηκεύσουμε τα cookies στη συσκευή σας, εφόσον είναι απολύτως αναγκαία για τη λειτουργία αυτής της ιστοσελίδας. Για όλους τους άλλους τύπους cookies χρειαζόμαστε την άδειά σας. Μπορείτε να αλλάξετε ή να καταργήσετε τη συναίνεσή σας ανά πάσα στιγμή μέσω της Δήλωσης για τα Cookies στην ιστοσελίδα μας. Μάθετε περισσότερα σχετικά με το ποιοι είμαστε, με το πως μπορείτε να επικοινωνήσετε μαζί μας και με το πως επεξεργαζόμαστε τα προσωπικά δεδομένα στην Πολιτική Προστασίας Προσωπικών Δεδομένων μας.
            </p>
            <h2>Αναγκαία Cookies</h2>
            <p>
                Τα απαραίτητα cookies βοηθούν στο να γίνει χρηστική μία ιστοσελίδα, επιτρέποντας βασικές λειτουργίες όπως την πλοήγηση και την πρόσβαση σε ασφαλείς περιοχές της ιστοσελίδας. Η ιστοσελίδα δεν μπορεί να λειτουργήσει σωστά χωρίς αυτά τα cookies.
            </p>
            <h2>Cookies Προτιμήσεων</h2>
            <p>
                Τα cookies προτίμησης επιτρέπουν σε μια ιστοσελίδα να θυμάται πληροφορίες που αλλάζουν τον τρόπο που συμπεριφέρεται η ιστοσελίδα ή την εμφάνισή της, όπως την προτιμώμενη γλώσσα ή την περιοχή στην οποία βρίσκεστε.
            </p>
            <h2>Cookies Στατιστικών</h2>
            <p>
                Τα Cookies στατιστικών βοηθούν τους ιδιοκτήτες ιστοχώρου να κατανοήσουν πώς αλληλεπιδρούν οι επισκέπτες με τις σελίδες συλλέγοντας και αναφέροντας πληροφορίες ανώνυμα.
            </p>
            <h2>Cookies Εμπορικής Προώθησης</h2>
            <p>
                Τα cookies Εμπορικής Προώθησης χρησιμοποιούνται για την παρακολούθηση των επισκεπτών στους ιστότοπους. Η πρόθεση είναι να εμφανίσουμε διαφημίσεις που είναι σχετικές και ελκυστικές για τους χρήστες και ως εκ τούτου πιο πολύτιμες για τρίτους εκδότες και διαφημιστές.
            </p>
            <h2>Αταξινόμητα Cookies</h2>
            <p>
                Τα αταξινόμητα cookies είναι τα cookies που είναι σε στάδιο ταξινόμησης, από κοινού με τους παρόχους μεμονωμένων cookies.
            </p>
            <h2>Πως χρησιμοποιούμε τα στοιχεία σας</h2>
            <p>
                Τα στοιχεία σας (εκτός από τις πληροφορίες πληρωμής) θα χρησιμοποιηθούν για να βελτιώσουμε τις υπηρεσίες μας.Σε περίπτωση που έχετε επιλέξει να λαμβάνετε τα ενημερωτικά μας δελτία (newsletter), ενδέχεται να χρησιμοποιήσουμε τα στοιχεία σας για να σας στείλουμε προσφορές και ενημερώσεις για προϊόντα και υπηρεσίες της AutoBesikos.gr ή άλλων εταιρειών, προσεκτικά επιλεγμένων, οι οποίες πιστεύουμε ότι μπορεί να σας ενδιαφέρουν. Μπορεί επίσης να χρησιμοποιήσουμε και να αναλύσουμε τις πληροφορίες που συλλέγουμε έτσι ώστε να βελτιώσουμε τις υπηρεσίες μας.
            </p>
            <h2>Δεν μοιραζόμαστε τα στοιχεία σας</h2>
            <p>
                Η AutoBesikos.gr δεν διανέμει ή μοιράζει τα στοιχεία σας σε τρίτους προμηθευτές, πωλητές, διανομείς ή άλλους σχετιζόμενους οργανισμούς με την δραστηριότητα μας.
            </p>
            <h2>Πως προστατεύουμε τα στοιχεία σας</h2>
            <p>
                Λαμβάνουμε όλα τα απαραίτητα μέτρα ασφαλείας για να προστατέψουμε τα προσωπικά σας δεδομένα από μη εξουσιοδοτημένη πρόσβαση ή/και αλλοίωση, αποκάλυψη και καταστροφή τους. Χρησιμοποιούμε όλες τις κατάλληλες διοικητικές, τεχνικές και φυσικές διαδικασίες ασφαλείας προκειμένου να προστατεύσουμε τις προσωπικές πληροφορίες που παρέχετε σε εμάς. Για παράδειγμα, μόνο οι εξουσιοδοτημένοι υπάλληλοι επιτρέπεται να έχουν πρόσβαση στα προσωπικά σας δεδομένα. Επιπλέον, χρησιμοποιούμε κρυπτογράφηση κατά τη διαδικασία διαβίβασης των προσωπικών σας δεδομένων μέσω διαδικτύου, καθώς και firewalls και συστήματα ανίχνευσης εισβολέων για να αποτρέψουμε μη εξουσιοδοτημένη πρόσβαση στα προσωπικά σας στοιχεία.
            </p>
            <h2>Προστασία προσωπικών δεδομένων παιδιών</h2>
            <p>
                Η AutoBesikos.gr δεν προβαίνει σε συναλλαγές με παιδιά. Εάν ένας χρήστης κάτω των 18 ετών προσπαθήσει να πραγματοποιήσει μια αγορά μέσω της AutoBesikos.gr, θα χρησιμοποιήσουμε τις προσωπικές του/της πληροφορίες μόνο για να τον/την ενημερώσουμε πως χρειάζεται γονική άδεια για να ολοκληρώσει οποιαδήποτε κράτηση.
            </p>
            <h2>Εξωτερικοί σύνδεσμοι</h2>
            <p>
                Η σελίδα AutoBesikos.gr μπορεί να περιέχει συνδέσμους για άλλες ιστοσελίδες. Δεν είμαστε υπεύθυνοι για τις πολιτικές προστασίας προσωπικών δεδομένων των άλλων ιστοσελίδων.
            </p>
            <h2>Επικοινωνήστε μαζί μας</h2>
            <p>
                Εάν έχετε οποιαδήποτε ερώτηση σχετικά με την παρούσα Πολιτική Προστασίας Προσωπικών Δεδομένων, παρακαλούμε επικοινωνήστε με την επιχείρηση μας στο <a href="tel:+302108001111">210 800 11 11</a>. Ανά πάσα στιγμή μπορείτε να επικοινωνήσετε μαζί μας στο <a href="mailto:autobesikos@gmail.com">autobesikos@gmail.com</a> και να ζητήσετε να σας διαγράψουμε από την λίστα επαφών μας (mailing list).</p>
        </section>
    </div>
</template>

<script>
    export default {
    }
</script>