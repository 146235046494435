<template>
    <div class="form-text form-password">
        <input autocomplete="new-password" :type="!showPassword ? 'password' : 'text'" :id="$attrs._id" v-model="password" minlength="8" maxlength="14" placeholder="********" :required="notRequired ? false : true" />
        <div>
            <PasswordEye color="#60676c" v-show="showPassword" @click="showPassword = !showPassword"/>
            <PasswordEyeSlash color="#98A9B5" v-show="!showPassword" @click="showPassword = !showPassword"/>
        </div>
    </div>
</template>

<script>
    import { PasswordEye, PasswordEyeSlash } from "../../Icons/Icons";

    export default {
        components: {
            PasswordEye, PasswordEyeSlash
        },
        props: {
            notRequired: Boolean
        },
        watch: {
            password: { handler() { this.emitFunction() }, deep: true },
        },
        data() {
            return {
                showPassword: false,
                password: '',
            }
        },
        methods: {
            emitFunction() {
                this.$emit('password_value', this.password);
            }
        }
    }
</script>