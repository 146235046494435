<template>
    <div class="payment success">
        <h1>Σύνοψη κράτησης</h1>
        <div class="payment-page success-page">
            <div class="payment-left">
                <CartCustomerInfo className="customer" title="Στοιχεία πελάτη" :data="success" />
                <CartCustomerInfo className="payment" title="Στοιχεία χρέωσης" :data="success" />
            </div>
            <div class="payment-right">
                <div>
                    <Payment paid :order="$attrs.vehicle" />
                </div>
            </div>
        </div>

        <div class="success-info">
            <Disclosure title="Αν παραγγείλω καινούργιο αυτοκίνητο αλλά τελικά δεν προχωρήσω στην αγορά του, θα μου επιστραφεί η προκαταβολή;" text="Σε περίπτωση που για οποιοδήποτε λόγο αποφασίσεις να μην προχωρήσεις στην αγορά του καινούργιου αυτοκινήτου που έχεις παραγγείλει, το ποσό της προκαταβολής που έχεις καταβάλει θα σου επιστραφεί στο ακέραιο." />
            <Disclosure title="Αν τελικά δεν προχωρήσω στην αγορά του αυτοκινήτου που με ενδιέφερε, θα μου επιστραφεί το ποσό της κράτησης;" text="Σε περίπτωση που για οποιοδήποτε λόγο αποφασίσεις να μην προχωρήσεις στην αγορά του αυτοκινήτου που σε ενδιαφέρει, το ποσό της κράτησης που έχεις καταβάλει θα σου επιστραφεί στο ακέραιο. Η διαδικασία ολοκληρώνεται εντός μιας εργάσιμης ημέρας από τη στιγμή που θα υπάρξει η σχετική ενημέρωση." />
            <Disclosure title="Πως θα μάθω αν εγκρίθηκε η χρηματοδότηση;" text="Θα σε ενημερώσουμε εμείς με κάθε λεπτομέρεια για την πορεία της αίτησής σου και θα λύσουμε οποιαδήποτε απορία έχεις. Σε περίπτωση που επιλέξεις να κάνεις μόνος σου τη διαδικασία σου online μέσω της <a href='/online-xrhmatodotish-apo-thn-santander'>Santander</a>, θα ενημερωθείς σύντομα από την ίδια την Τράπεζα. Αν πάλι έχεις απορίες μπορείς να επικοινωνήσεις με το αρμόδιο τμήμα της εταιρείας μας." />
            <Disclosure title="Πότε θα παραλάβω το αυτοκίνητο μου;" text="Διακρινόμαστε (και) για την ταχύτητά μας. Είμαστε γρήγοροι και αποτελεσματικοί. Συνήθως σε 5 εργάσιμες ημέρες από την στιγμή που υπάρξει εξόφληση και δεν συντρέχουν λόγοι ανωτέρας βίας (απεργίες, φυσικές καταστροφές κ.α.), μπορείς να παραλάβεις το αυτοκίνητο που έχεις επιλέξει." />
            <Disclosure title="Μπορώ να παραλάβω το νέο μου αυτοκίνητο στην πόρτα μου;" text="Πες μας τον τόπο διαμονής σου εντός Ελλάδας και τα υπόλοιπα τα αναλαμβάνουμε εμείς! Στην Αττική η παράδοση του οχήματος γίνεται δωρεάν. Για παραδόσεις εκτός Αττικής υπάρχουν έξοδα αποστολής που υπολογίζονται ανάλογα με την περιοχή και τον τρόπο παράδοσης. Αυτά τα έξοδα επιβαρύνουν τον πελάτη." />
        </div>
    </div>

</template>

<script>
    import CartCustomerInfo from "../../Components/Cart/CartCustomerInfo.vue";
    import Button from "../../Components/Button/Button.vue";
    import Disclosure from "../../Components/Disclosure/Disclosure.vue";
    import Payment from "../../Components/Cart/Payment.vue";

    export default {
        components: {
            Button, CartCustomerInfo, Disclosure, Payment,
        },
        props: {
        },
        setup(props, { slots, attrs }) {
            const success = {
                name: attrs.order.name,
                lastname: attrs.order.lname,
                email: attrs.order.email,
                tel: attrs.order.phone,
                town: attrs.order.town,
                country: attrs.order.country,
                county: attrs.order.state,
                postalcode: attrs.order.postal_code,
                text: attrs.order.comments,
            }

            return { success }
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>