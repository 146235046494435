<template>
    <svg data-name="Icon feather-printer" xmlns="http://www.w3.org/2000/svg" :fill="color ? color : 'currentColor'" :width="width ? width : '15.697'" :height="height ? height : '15.697'" viewBox="0 0 15.697 15.697">
        <path data-name="Path 13016" d="M16.776,7.922a.714.714,0,0,1-.714-.714V2.927H8.927V7.208a.714.714,0,0,1-1.427,0V2.214A.714.714,0,0,1,8.214,1.5h8.562a.714.714,0,0,1,.714.714V7.208A.714.714,0,0,1,16.776,7.922Z" transform="translate(-4.646 -1.5)"/>
        <path data-name="Path 13017" d="M15.057,19.849H13.63a.714.714,0,0,1,0-1.427h1.427a.714.714,0,0,0,.714-.714V14.141a.714.714,0,0,0-.714-.714H3.641a.714.714,0,0,0-.714.714v3.568a.714.714,0,0,0,.714.714H5.068a.714.714,0,1,1,0,1.427H3.641A2.143,2.143,0,0,1,1.5,17.708V14.141A2.143,2.143,0,0,1,3.641,12H15.057A2.143,2.143,0,0,1,17.2,14.141v3.568A2.143,2.143,0,0,1,15.057,19.849Z" transform="translate(-1.5 -7.005)"/>
        <path data-name="Path 13018" d="M8.214,19.5h8.562a.714.714,0,0,1,.714.714v5.708a.714.714,0,0,1-.714.714H8.214a.714.714,0,0,1-.714-.714V20.214A.714.714,0,0,1,8.214,19.5Zm7.849,1.427H8.927v4.281h7.135Z" transform="translate(-4.646 -10.938)"/>
    </svg>

</template>

<script>
    export default {
        name: "Print",
        props: ["color", "width", "height"],
    }
</script>