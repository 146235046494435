<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '38.844'" :height="height ? height : '31.549'" viewBox="0 0 38.844 31.549">
        <path d="M34.851,55.944c.025.345.025.69.025,1.035,0,10.524-8.01,22.651-22.651,22.651A22.5,22.5,0,0,1,0,76.057a16.469,16.469,0,0,0,1.922.1,15.944,15.944,0,0,0,9.884-3.4,7.975,7.975,0,0,1-7.444-5.521,10.04,10.04,0,0,0,1.5.123,8.42,8.42,0,0,0,2.1-.271,7.962,7.962,0,0,1-6.384-7.813v-.1a8.018,8.018,0,0,0,3.6,1.01A7.973,7.973,0,0,1,2.711,49.536a22.629,22.629,0,0,0,16.415,8.331,8.987,8.987,0,0,1-.2-1.824A7.969,7.969,0,0,1,32.707,50.6a15.674,15.674,0,0,0,5.053-1.922,7.94,7.94,0,0,1-3.5,4.387,15.959,15.959,0,0,0,4.584-1.232A17.113,17.113,0,0,1,34.851,55.944Z" transform="translate(0 -48.082)" :fill="color ? color : '#fff'"/>
    </svg>
</template>

<script>
    export default {
        name: "Twitter",
        props: ["color", "width", "height"],
    }
</script>