<template>
    <GoogleMap api-key="AIzaSyDB9hdMsfD6Kbt58S2BYkEEDrK9BdjdReY" style="width: 100%; height: 535px;" :center="center" :zoom="zoom">
        <MarkerCluster>
            <CustomMarker v-for="(location, i) in locations" :options="{ position: location }" :key="i">
                <a :href="location.href" target="_blank" style="text-align: center">
                    <div class="about-us-map-marker">
                        <Pin width="27" height="27"/>
                        <h3>{{ location.address }}</h3>
                    </div>
                </a>
            </CustomMarker>
        </MarkerCluster>
    </GoogleMap>
</template>

<script>
    import { Pin } from "../../Icons/Icons";
    import { GoogleMap, MarkerCluster, CustomControl, CustomMarker } from "vue3-google-map";

    export default {
        components: {
            Pin,
            GoogleMap, MarkerCluster, CustomMarker, CustomControl
        },
        setup() {
            const center = { lat: 38.0215153421061, lng: 23.73060679186102 }
            const locations = [
                { title: 'Αθήνα', address: 'Λ. Αχαρνών 458', lat: 38.024851, lng: 23.731303, href: 'https://goo.gl/maps/Uy6CMVKPRxWbcuXZ7' },
                { title: 'Κηφισιά', address: 'Λ. Κηφισίας 323', lat: 38.080474, lng: 23.814238, href: 'https://goo.gl/maps/L84PAYgrBxYSFZWJ6' },
            ]
            const zoom = 11;
            
            return { center, locations, zoom }
        }
    }
</script>