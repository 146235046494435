<template>
    <svg id="Icon_feather-share-2" data-name="Icon feather-share-2" xmlns="http://www.w3.org/2000/svg" :fill="color ? color : 'currentColor'" :width="width ? width : '16.21'" :height="height ? height : '17.831'" viewBox="0 0 16.21 17.831">
        <path data-name="Path 13019" d="M24.242,1.5A3.242,3.242,0,1,1,21,4.742,3.246,3.246,0,0,1,24.242,1.5Zm0,4.863a1.621,1.621,0,1,0-1.621-1.621A1.623,1.623,0,0,0,24.242,6.363Z" transform="translate(-11.274 -1.5)"/>
        <path data-name="Path 13020" d="M6.242,12A3.242,3.242,0,1,1,3,15.242,3.246,3.246,0,0,1,6.242,12Zm0,4.863a1.621,1.621,0,1,0-1.621-1.621A1.623,1.623,0,0,0,6.242,16.863Z" transform="translate(-3 -6.326)"/>
        <path data-name="Path 13021" d="M24.242,22.5A3.242,3.242,0,1,1,21,25.742,3.246,3.246,0,0,1,24.242,22.5Zm0,4.863a1.621,1.621,0,1,0-1.621-1.621A1.623,1.623,0,0,0,24.242,27.363Z" transform="translate(-11.274 -11.153)"/>
        <path data-name="Path 13022" d="M17.731,23.612a.807.807,0,0,1-.407-.11l-5.536-3.226a.811.811,0,1,1,.816-1.4L18.139,22.1a.811.811,0,0,1-.409,1.511Z" transform="translate(-6.854 -9.436)"/>
        <path data-name="Path 13023" d="M12.2,13.112a.811.811,0,0,1-.409-1.511l5.528-3.226a.811.811,0,1,1,.817,1.4L12.6,13A.807.807,0,0,1,12.2,13.112Z" transform="translate(-6.854 -4.609)"/>
    </svg>

</template>

<script>
    export default {
        name: "Share",
        props: ["color", "width", "height"],
    }
</script>