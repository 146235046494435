<template>
    <section class="factor-code">
        <h1>Επιβεβαίωση αριθμού</h1>
        <p>Έχετε λάβει στο email του λογαριασμού σας έναν εξαψήφιο κωδικό επαλήθευσης για την ταυτοποίησή σας. Συμπληρώστε τον κωδικό στο παρακάτω πεδίο.</p>
        <br/>
        <form class="form-user" @submit.prevent="submitForm">
            <div class="form-text">
                <label for="two-factor">Two Factor Code</label>
                <input type="number" inputmode="decimal" id="two-factor" v-model="form.two_factor_code" placeholder="" required/>
                <InputError :message="$attrs.errors.two_factor_code"/>
            </div>
            <div class="form-user-bottom-button">
                <Button @click="submitForm" inline submit>Επιβεβαίωση</Button>
            </div>
        </form>
    </section>
</template>

<script>
    import Button from "../../Components/Button/Button.vue";
    import { router, useForm } from "@inertiajs/vue3";
    import InputError from "../../Components/Form/InputError.vue";

    export default {
        components: {
            Button, InputError
        },
        setup(props) {
            const form = useForm({
                two_factor_code: '',
            });

            return { form }
        },
        data() {
            return {
            }
        },
        methods: {
            submitForm() {
                router.post(`/verify`, {
                    two_factor_code: String(this.form.two_factor_code)
                })
            },
        }
    }
</script>