<template>
    <section class="popup-small" v-show="$global.popup.open && $global.popup.id === id" >
        <div class="popup-blur"></div>
        <div class="popup-small-main">
            <div class="popup-small-close">
                <Close width="35" height="35" color="#000" @click="triggerPopup(false, null)" />
            </div>
            <div class="popup-small-content">
                <slot />
            </div>
        </div>
    </section>
</template>

<script>
    import { Close } from '../../Icons/Icons';

    export default {
        components: {
            Close
        },
        props: {
            id: Number
        },
        data() {
            return {
                open: false,
            }
        },
        methods: {
            triggerPopup(value, id) {
                if ( !window.localStorage.getItem('spin_autobesikos_stop') && this.$global.popup.id === 11 && !value ) {
                    window.localStorage.setItem('spin_autobesikos_stop', true);
                }
                this.$global.popup.open = value;
                this.$global.popup.id = id;
                this.updatedTrigger();
            },
            emitOpen(value) {
                this.$emit('popup_open', value);
            },
            updatedTrigger() {
                if ( !window.localStorage.getItem('popup_autobesikos_black_friday_2024') && this.$global.popup.id === 12 ) {
                    window.localStorage.setItem('popup_autobesikos_black_friday_2024', true);
                }
                if ( this.$global.popup.open && !this.open ) {
                    this.open = true;
                    this.emitOpen(true);
                    document.body.classList.add("hide-scroll");
                } else if ( !this.$global.popup.open && this.open) {
                    this.open = false;
                    document.body.classList.remove("hide-scroll");
                    this.emitOpen(false);
                }
            }
        },
        mounted() {
            this.updatedTrigger();
        },
    }
</script>