<template>
    <Login :auth="$attrs.auth" :errors="$attrs.errors" />
</template>

<script>
    import Login from "./Login.vue";

    export default {
        components: {
            Login,
        },
    }
</script>