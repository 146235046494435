<template>
    <div class="account-page" :class="sidebarIsOpen && 'account-page-open'">
        <UserSidebar @openMenu="SidebarMenu" />
        <div class="account-info">
            <section class="account-info-top">
                <h1>Αλλαγή κωδικού πρόσβασης</h1>
            </section>
            <section class="account-info-main">
                <p>Από εδώ μπορείτε ν’ αλλάξετε τον κωδικό πρόσβαση σας</p>
                <form class="form-user account-info-password" @submit.prevent="changePassword">
                    <div class="form-text">
                        <label for="current_password">Παλιός κωδικός*</label>
                        <Password @password_value="getCurrentPassword" _id="current_password" />
                        <InputError :message="errors.current_password" />
                    </div>
                    <div class="form-text">
                        <label for="password">Νέος κωδικός*</label>
                        <Password @password_value="getPassword" _id="password" />
                        <InputError :message="errors.password" />
                    </div>
                    <div class="form-text">
                        <label for="password_confirmation">Επαλήθευση Κωδικού*</label>
                        <Password @password_value="getConfirmationPassword" _id="password_confirmation" />
                        <InputError :message="errors.password_confirmation" />
                    </div>

                    <div class="form-bottom">
                        <div class="form-bottom-button" >
                            <Button @click="changePassword" submit inline :disable="!box.filled">Αλλαγή κωδικού πρόσβασης</Button>
                        </div>
                        <div class="form-user-bottom-pass">
                            <Link href="/account/forgot-password" title="Ξέχασες τον κωδικό σου?">Ξέχασες τον κωδικό πρόβασης</Link>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
    import UserSidebar from "../../Components/User/UserSidebar.vue";
    import Button from "../../Components/Button/Button.vue";
    import { useForm, router } from "@inertiajs/vue3";
    import Password from "../../Components/Form/Password.vue";
    import { reactive } from "vue";
    import InputError from "../../Components/Form/InputError.vue";

    export default {
        components: {
            UserSidebar, Button, Password, InputError
        },
        setup(props, { slots, attrs }) {
            const data = useForm({
                email: attrs.auth.user.email,
                current_password: '',
                password: '',
                password_confirmation: '',
            });

            const errors = useForm({
                current_password: '',
                password: '',
                password_confirmation: '',
            });

            const box = reactive({
                filled: false,
            })

            return { data, box, errors };
        },
        data() {
            return {
                sidebarIsOpen: false,
            }
        },
        watch: {
            data: { handler() { this.checkPassword() }, deep: true },
        },
        methods: {
            checkPassword() {
                if ( this.data.current_password.length > 0 && this.data.password.length > 0 && this.data.password_confirmation.length > 0 && this.data.password === this.data.password_confirmation && this.data.current_password !== this.data.password ) {
                    this.box.filled = true;
                } else {
                    this.box.filled = false;
                }
            },
            getCurrentPassword(value) { this.data.current_password = value; },
            getPassword(value) { this.data.password = value; },
            getConfirmationPassword(value) { this.data.password_confirmation = value; },
            changePassword() {
                this.data.put('/password', { 
                    onSuccess: (response) => {
                        this.errors.reset();
                        this.$helper.getFormMessage(true, this.$global.message, true, 'Ο κωδικός άλλαξε', 'green', response);
                        router.get(window.location.href, {})
                    },
                    onError: (error) => {
                        const getErrors = Object.entries(error);
                        this.errors.reset();

                        if (getErrors.length > 0) {
                            getErrors.map((e) => { this.errors[e[0]] = e[1]; })
                        } else {
                            this.$helper.getFormMessage(false, this.$global.message, true, 'Κάτι πήγε στραβά', 'red', error);
                        }
                    }
                })
            },
            SidebarMenu(value) {
                this.sidebarIsOpen = value;
            }
        }
    }
</script>