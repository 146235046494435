<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '15.949'" :height="height ? height : '17.554'" viewBox="0 0 15.949 17.554">
        <g transform="translate(-2.25 -1.25)">
            <rect data-name="Rectangle 149406" width="14.449" height="14.449" rx="2" transform="translate(3 3.605)" fill="none" :stroke="color ? color : '#000'"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line data-name="Line 509" y2="3.211" transform="translate(13.435 2)" fill="none" :stroke="color ? color : '#000'"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line data-name="Line 510" y2="3.211" transform="translate(7.014 2)" fill="none" :stroke="color ? color : '#000'"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line data-name="Line 511" x2="14.449" transform="translate(3 8.422)" fill="none" :stroke="color ? color : '#000'"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Calendar",
        props: ["color", "width", "height"],
    }
</script>