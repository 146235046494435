<template>
    <!-- Desktop -->
    <!-- Filters Desktop -->
    <section v-if="!$global.isMobile" class="filters-desktop-main form-main desktop-only">
        <div class="filters-desktop-top">
            <h2>Φίλτρα</h2>
            <p @click="clearFilters += 1">Καθαρισμός</p>
        </div>
        <div class="filters-middle no-select">
            <FiltersContent :data="data" :active="active" :clearFilters="clearFilters" :perpage="$attrs.perpage" :sorting="$attrs.sorting"/>
        </div>
    </section>

    <!-- Mobile -->
    <!-- Button -->
    <div v-if="$global.isMobile" class="filters-button mobile-only">
        <p @click="filterButton"><Funnel width="17" height="17" /> Φίλτρα</p>
    </div>

    <!-- Filters Mobile -->
    <section v-if="$global.isMobile" class="filters-mobile-main mobile-only form-main" :class="content.mobile && 'filters-mobile-main-active'">
        <div class="filters-mobile-top">
            <h2>Φίλτρα</h2>
            <Close width="28" height="28" @click="filterButton" />
        </div>
        <div class="filters-mobile-middle">
            <div class="filters-middle no-select">
                <FiltersContent mobile :data="data" :active="active" :clearFilters="clearFilters" :triggerFilters="triggerFilters" :perpage="$attrs.perpage" :sorting="$attrs.sorting" />
            </div>
        </div>
        <div class="filters-mobile-bottom">
            <h2 @click="clearFilters += 1">Καθαρισμός</h2>
            <div @click="mobileFilterButton">
                <Button button inline >Αυτοκίνητα</Button>
            </div>
        </div>
    </section>
</template>

<script>
    import { Funnel, Close } from "../../Icons/Icons";
    import { reactive } from "vue";
    import FiltersContent from "../Filters/FiltersContent.vue";
    import Button from "../../Components/Button/Button.vue";

    export default {
        components: {
            Funnel, Close,
            FiltersContent, Button,
        },
        props: {
            data: Object,
            active: Object,
        },
        setup(props, { slots, attrs }) {
            const content = reactive({
                mobile: false
            });

            return { content }
        },
        data() {
            return {
                clearFilters: 0,
                triggerFilters: 0,
            }
        },
        methods: {
            filterButton() {
                this.content.mobile = !this.content.mobile;
                document.body.classList.toggle("hide-scroll");
            },
            mobileFilterButton() {
                this.filterButton();
                this.triggerFilters += 1;
            }
        }

    }
</script>