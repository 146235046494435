<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '16.474'" :height="height ? height : '30.759'" viewBox="0 0 16.474 30.759">
        <path d="M38.284,17.3l.854-5.567H33.8V8.123c0-1.523.746-3.007,3.138-3.007h2.428V.376A29.612,29.612,0,0,0,35.054,0c-4.4,0-7.274,2.666-7.274,7.493v4.243H22.89V17.3h4.89V30.759H33.8V17.3Z" transform="translate(-22.89)" :fill="color ? color : '#fff'"/>
    </svg>

</template>

<script>
    export default {
        name: "Facebook",
        props: ["color", "width", "height"],
    }
</script>