<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '20'" :height="height ? height : '22.008'" viewBox="0 0 20 22.008">
        <g transform="translate(-2 -0.992)">
            <rect data-name="Rectangle 149398" width="18" height="11" rx="2" transform="translate(3 11)" fill="none" :stroke="color ? color : '#98a9b5'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path data-name="Path 12931" d="M7,11V7a5,5,0,0,1,9.9-1" fill="none" :stroke="color ? color : '#98a9b5'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Unlock",
        props: ["color", "width", "height"],
    }
</script>