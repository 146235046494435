<template>
    <div class="pagination">
        <!-- Desktop -->
        <div v-if="pages.length > 3" v-for="(page, key) in pages" :key="page.label" class="desktop-only">
            <!-- Previous -->
            <Link v-if="(page.label.includes('Prev') && page.url)" :href="(page.url)" class="pagination-prev">
                <Arrow/>
            </Link>
            <!-- Link -->
            <Link v-else-if="!page.label.includes('Prev') && !page.label.includes('Next') && !page.label.includes('...')" :href="page.url">
                <p class="pagination-link" :class="page.active && 'pagination-link-active'">{{ page.label }}</p>
            </Link>
            <!-- Dots -->
            <p v-else-if="page.label.includes('...')" class="pagination-link">{{ page.label }}</p>
            <!-- Next -->
            <Link v-else-if="(page.label.includes('Next') && page.url)" :href="page.url" class="pagination-next">
                <Arrow />
            </Link>
        </div>
        <!-- Mobile -->
        <div v-if="pages.length > 3" v-for="(page) in mobilePages" :key="page.label+'-mobile'" class="mobile-only">
            <!-- Previous -->
            <Link v-if="(page.label.includes('Prev') && page.url)" :href="(page.url)" class="pagination-prev">
                <Arrow/>
            </Link>
            <!-- Dots -->
            <p v-if="page.label.includes('...')" class="pagination-link">{{ page.label }}</p>
            <!-- Link -->
            <Link v-if="(!page.label.includes('Next') && !page.label.includes('Prev')) && !page.label.includes('...')" :href="page.url">
                <p class="pagination-link" :class="page.active && 'pagination-link-active'">{{ page.label }}</p>
            </Link>
            <!-- Next -->
            <Link v-if="(page.label.includes('Next') && page.url)" :href="page.url" class="pagination-next">
                <Arrow class="rotate-180"/>
            </Link>
        </div>
    </div>
</template>

<script>
    import { Arrow } from "../../Icons/Icons";

    export default {
        props: {
            pages: Array,
        },
        components: {
            Arrow
        },
        computed: {
            mobilePages() {
                let data = [];
                let onceFirst = false;
                let onceLast = false;

                this.pages.forEach((page, index) => {
                    const lastPage = index === this.pages.length - 2;
                    const lastPageNumber = this.pages.length - 2;

                    const paged = (item) => { 
                       return { active: item.active, label: item.label, url: item.url, page: item.url ? item.url.replace(/(.*page=)/g, '') : null }
                    }

                    if ( page.label === '1' ) { data.push(paged(page)) }

                    if ( page.active && Number(page.label) >= 4 && this.pages.length > 6 && !onceFirst ) {
                        onceFirst = true;
                        data.push({ active: null, label: '...', url: null, page: null })
                    }

                    if ( page.active && this.pages[index-1] ) {
                        if ( Number(this.pages[index-1].label) !== 1 && !this.pages[index-1].label.includes('Prev') ) {
                            data.push(paged(this.pages[index-1]))
                        }
                    }

                    if ( page.active && page.label !== '1' ) { data.push(paged(page)) }

                    if ( page.active && this.pages[index+1] && !this.pages[index+1].label.includes('Next') ) {
                        if ( Number(this.pages[index+1].label) !== lastPage ) {
                            data.push(paged(this.pages[index+1]))
                        }
                    }

                    if ( page.active && Number(page.label) <= Number(this.pages[lastPageNumber].label) - 3 && this.pages.length > 6 && !onceLast ) {
                        onceLast = true;
                        data.push({ active: null, label: '...', url: null, page: null })
                    }

                    if ( page.active && page.label !== this.pages[lastPageNumber].label && Number(page.label) + 1 !== Number(this.pages[lastPageNumber].label) ) {
                        data.push(paged(this.pages[lastPageNumber]))
                    }

                    if ( page.label.includes('Prev') || page.label.includes('Next') ) { 
                        data.push(paged(page))
                    }
                });

                return data.filter((item) => item !== undefined )
            },
        }
    }
</script>