<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '18.52'" :height="height ? height : '23.15'" viewBox="0 0 18.52 23.15">
        <path d="M21.363,7.788H17.89V6.63a4.63,4.63,0,0,0-9.26,0V7.788H5.158A1.158,1.158,0,0,0,4,8.945V21.678A3.473,3.473,0,0,0,7.473,25.15H19.048a3.473,3.473,0,0,0,3.473-3.473V8.945A1.158,1.158,0,0,0,21.363,7.788ZM10.945,6.63a2.315,2.315,0,0,1,4.63,0V7.788h-4.63Zm9.26,15.048a1.158,1.158,0,0,1-1.158,1.158H7.473a1.158,1.158,0,0,1-1.158-1.158V10.1H8.63V11.26a1.158,1.158,0,1,0,2.315,0V10.1h4.63V11.26a1.158,1.158,0,1,0,2.315,0V10.1h2.315Z" transform="translate(-4 -2)" :fill="color ? color : '#98a9b5'"/>
    </svg>
</template>

<script>
    export default {
        name: "Cart",
        props: ["color", "width", "height"],
    }
</script>