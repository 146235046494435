<template>
    <div class="search" :class="activeBox && 'search-active'">
        <div class="search-input">
            <div class="search-input-icon">
                <Search color="#98A9B5" />
            </div>
            <input placeholder="Γράψτε τον όρο αναζήτησης" v-model="active.value" @click="openBox" @keypress="searchBox" />
            <div v-if="activeBox" class="search-input-close mobile-only" @click="closeBox">
                <p>Κλείσιμο</p>
            </div>
        </div>
        <div class="search-border" :class="active.value.length > 0 && query.search.length === 0 ? 'search-border-active' : ''" v-if="activeBox">
            <div class="search-border-content">
                <div class="search-border-history" v-if="active.value.length === 0 || active.value === ''">
                    <div class="search-border-history-item" v-for="item in query.history" :key="item">
                        <a :href="`/vehicles?s=${item}`" @click="setLocalStorage(item, 'reorder')">{{ item }}</a>
                        <div @click="setLocalStorage(item, 'remove')">
                            <Trash :width="mobile ? 19 : 16" :height="mobile ? 19 : 16" />
                        </div>
                    </div>
                </div>
                <div class="search-border-new">
                    <div class="search-border-new-item" v-for="item in query.search" :key="item.name">
                        <a :href="`/vehicles?s=${item.name}`" @click="setLocalStorage(item, 'add')">
                            <p v-html="item.text"></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive, ref } from "vue";
    import axios from "axios";
    import { Trash, Search } from "../../Icons/Icons";

    export default {
        components: {
            Trash, Search
        },
        props: {
            mobile: Boolean
        },
        setup(props, { slots, attrs }) {
            const active = reactive({
                value: '',
            })

            const query = reactive({
                search: [],
                history: [],
                prev_length: 0,
                disabled: false,
            })

            const activeBox = ref(false);

            return { active, query, activeBox }
        },
        data() {
            return {
                debounceSearch: null,
            }
        },
        emits: ['search_item'],
        watch: {
            active: { handler() {
                const timer = 600;
                // Debounce effect
                clearTimeout(this.debounceSearch);
                this.debounceSearch = setTimeout(() => { 
                    this.search();
                    this.$emit('search_item', this.active.value);
                 }, timer);
            }, deep: true },
        },
        methods: {
            axiosRequest() {
                axios.get(`${window.location.origin}/api/query-suggestions?search=${this.active.value}`).then((response) => {
                    // Highlight Regex
                    const regex = new RegExp(this.active.value, 'gi');
                    // Empty List
                    const list = [];

                    // Change the current array
                    response.data.map((item) => {
                        list.push({
                            text: item.replace(regex, '<strong>$&</strong>'), name: item,
                        });
                    })

                    if ( response.data.length === 0 && this.active.value.length > 15 ) {
                        this.query.disabled = true;
                    } else {
                        this.activeBox = true;
                        this.query.prev_length = this.active.value.length + 1;
                    }

                    return this.query.search = list;
                });
            },
            search() {
                if ( !this.query.disabled ) {
                    this.axiosRequest();
                } else {
                    if ( this.query.prev_length >= this.active.value.length ) {
                        this.query.disabled = false;
                        this.axiosRequest();
                    }
                }
            },
            closeListenerBox(e) {
                if (!this.$el.contains(e.target)) {
                    this.activeBox = false;
                }
            },
            searchBox(e) {
                if (e.keyCode === 13) {
                    this.setLocalStorage(this.active.value, 'add');
                    window.location.href = `${window.location.origin}/vehicles?s=${this.active.value}`;
                }
            },
            closeBox() {
                if ( !this.mobile ) {
                    this.activeBox = false;
                } else {
                    this.activeBox = false;
                    document.body.classList.remove("hide-scroll");
                }
            },
            openBox() {
                if ( this.query.search.length > 0 || this.query.history.length > 0 && !this.mobile ) {
                    this.activeBox = true;
                }

                if ( this.mobile ) {
                    this.activeBox = true;
                    document.body.classList.add("hide-scroll");
                }
            },
            setLocalStorage(text, target) {
                let item;
                if ( text ) { if ( text.name ) { item = text.name; } else { item = text; } 
                } else { item = null; }

                if ( !window.localStorage.getItem('history_cars') ) {
                    window.localStorage.setItem('history_cars', JSON.stringify([item]));
                } else {
                    const getArray = JSON.parse(window.localStorage.getItem('history_cars'));
                    const indexItem = getArray.indexOf(item);
                    const newArray = (array, value) => [...array, value];
                    
                    if ( target === 'add' ) {
                        if ( indexItem === -1 ) {
                            if ( getArray.length < 4 ) {
                                window.localStorage.setItem('history_cars', JSON.stringify(newArray(getArray, item)));
                            } else {
                                const removeFirstItem = getArray.slice(1);

                                window.localStorage.setItem('history_cars', JSON.stringify(newArray(removeFirstItem, item)));
                            }
                        } else if ( indexItem > -1 && getArray.length > 1 ) {
                            const removeIndexedItem = getArray.filter((name) => name !== item);
                            
                            window.localStorage.setItem('history_cars', JSON.stringify(newArray(removeIndexedItem, item)));
                        }
                    }

                    if ( target === 'reorder' ) {
                        const currentItem = item;
                        const removeItem = getArray.filter((name) => name !== currentItem);
                        const addItem = newArray(removeItem, item);

                        window.localStorage.setItem('history_cars', JSON.stringify(addItem));
                        this.query.history = addItem;
                    }

                    if ( target === 'remove' ) {
                        const removeItem = getArray.filter((name) => name !== item);

                        window.localStorage.setItem('history_cars', JSON.stringify(removeItem));
                        this.query.history = removeItem;
                    }
                }
            },
        },
        mounted() {
            if ( !this.mobile ) {
                document.addEventListener('click', this.closeListenerBox);
            }
            if ( window.localStorage.getItem('history_cars') ) {
                this.query.history = JSON.parse(window.localStorage.getItem('history_cars'));
            }
        },
        beforeUnmount() {
            if ( !this.mobile ) {
                document.removeEventListener('click',this.closeListenerBox);
            }
            if ( document.body.classList.contains("hide-scroll") ) {
                document.body.classList.remove("hide-scroll")
            }
        }
    }
</script>