<template>
    <div class="error-page">
        <h1>Oops! Κάτι πήγε στραβά</h1>
        <p>Φαίνεται πως η πληρωμή δεν ολοκληρώθηκε επιτυχώς. Κάντε κλικ στο παρακάτω κουμπί για να επαναλάβετε τη διαδικασία.</p>
        <div class="error-bottom">
            <Button inline :href="url">Ολοκλήρωση αγοράς</Button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        setup(props, { slots, attrs }) {

        },
        data() {
            return {
                url: '',
            }
        },
        methods: {
            getURL() {
                this.url = window.location.pathname.replace('fail', 'checkout');
            }
        },
        mounted() {
            this.getURL()
        }
    }
</script>