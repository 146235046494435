<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '27.337'" :height="height ? height : '27.933'" viewBox="0 0 27.337 27.933">
        <g transform="translate(-0.214 -0.533)">
            <g data-name="Group 19720" transform="translate(0.214 0.533)">
            <g data-name="Group 19719" transform="translate(0 0)">
                <path data-name="Path 12988" d="M5.935,19.792a8.261,8.261,0,0,1,.42-2.593L1.648,13.6a14.062,14.062,0,0,0,0,12.37l4.7-3.6a8.264,8.264,0,0,1-.415-2.581" transform="translate(-0.214 -5.825)" fill="#fbbc05" fill-rule="evenodd"/>
                <path data-name="Path 12989" d="M15.176,6.247a8.093,8.093,0,0,1,5.15,1.841l4.069-4.063A13.978,13.978,0,0,0,2.623,8.313l4.707,3.595a8.242,8.242,0,0,1,7.845-5.66" transform="translate(-1.189 -0.533)" fill="#eb4335" fill-rule="evenodd"/>
                <path data-name="Path 12990" d="M15.176,34.017a8.242,8.242,0,0,1-7.845-5.66L2.623,31.95a13.945,13.945,0,0,0,12.552,7.78,13.329,13.329,0,0,0,9.113-3.481L19.821,32.8a8.651,8.651,0,0,1-4.645,1.221" transform="translate(-1.189 -11.797)" fill="#34a853" fill-rule="evenodd"/>
                <path data-name="Path 12991" d="M37.064,22.273a11.571,11.571,0,0,0-.318-2.539H23.714v5.4h7.5A6.29,6.29,0,0,1,28.358,29.3l4.468,3.454a13.891,13.891,0,0,0,4.238-10.486" transform="translate(-9.727 -8.306)" fill="#4285f4" fill-rule="evenodd"/>
            </g>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "GoogleLogo",
        props: ["width", "height"],
    }
</script>