<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width ? width : '226'" :height="height ? height : '17.449'" viewBox="0 0 228 19">
        <g data-name="Menu logo" transform="translate(0 0)">
            <path data-name="Path 12915" d="M10.159,2.829l5.9,10.207H4.266Zm-1-1.109L0,17.555H1.651L3.435,14.47H16.9l1.784,3.085H20.33L11.171,1.72Z" transform="translate(0 -0.9)" :fill="color ? color : '#1a1818'"/>
            <g data-name="Group 19373" transform="translate(0 0)">
            <g data-name="Group 19372" clip-path="url(#clip-path)">
                <path data-name="Path 12916" d="M56.614,17.554q-4.6,0-6.64-1.662-2.3-1.833-2.3-6.255V1.72h1.434V9.637q0,3.868,1.952,5.291,1.627,1.193,5.555,1.193t5.556-1.193q1.951-1.446,1.952-5.291V1.72h1.434V9.637q0,4.423-2.3,6.255-2.048,1.663-6.64,1.662" transform="translate(-24.957 -0.9)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12917" d="M100.482,17.555H99.048V3.166H90.818V1.72h17.895V3.166h-8.23Z" transform="translate(-47.543 -0.901)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12918" d="M142.879,17.554q-4.712,0-7.122-1.614-2.856-1.916-2.856-6.3t2.856-6.315q2.4-1.6,7.122-1.6,4.7,0,7.11,1.627,2.856,1.917,2.856,6.29t-2.856,6.3q-2.41,1.614-7.11,1.614m0-14.388A12.119,12.119,0,0,0,136.926,4.3q-2.591,1.506-2.591,5.338t2.591,5.351a12.242,12.242,0,0,0,5.953,1.133,12.085,12.085,0,0,0,5.941-1.133q2.591-1.518,2.591-5.351T148.821,4.3a12.09,12.09,0,0,0-5.941-1.133" transform="translate(-69.575 -0.9)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12919" d="M200.335,12.34q0,5.109-10.279,5.109H180.8V0H190q10.34,0,10.34,5.146a3.791,3.791,0,0,1-2.193,3.591,3.8,3.8,0,0,1,2.193,3.6M197.25,5.146q0-2.086-5.544-2.085l-7.821.024V7.194h7.857q5.506,0,5.507-2.048m0,7.194q0-2.073-5.568-2.073l-7.8.012V14.4h7.857q5.506,0,5.507-2.06" transform="translate(-94.65 0)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12920" d="M228.27,17.449V0h17.486V3.085h-14.4v4.11h14.4V10.28h-14.4V14.4h14.4v3.049Z" transform="translate(-119.5 0)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12921" d="M293.036,12.34q0,3.47-4.3,4.592a33.7,33.7,0,0,1-7.05.518,24.574,24.574,0,0,1-5.989-.542,5.655,5.655,0,0,1-4.23-3.723l2.952-.82q.723,1.518,3.892,1.9a47.358,47.358,0,0,0,4.748.144q6.892,0,6.893-2.073,0-1.494-3.579-1.916-1.181-.145-4.712-.144-10.219,0-10.219-5.134,0-3.363,4.036-4.507A26.709,26.709,0,0,1,282.238,0a27.066,27.066,0,0,1,6.315.518q3.614.941,4.459,3.9l-2.953.759q-.638-1.567-3.832-1.977a47.609,47.609,0,0,0-4.773-.145q-6.929,0-6.929,2.085,0,1.494,3.471,1.9,1.241.144,4.615.144,10.423,0,10.424,5.146" transform="translate(-142.1 0)" :fill="color ? color : '#1a1818'"/>
                <rect data-name="Rectangle 149344" width="3.085" height="17.449" transform="translate(154.021)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12922" d="M350.945,17.449l-11.677-6.712v6.712h-3.085V0h3.085V6.749L350.945,0h6.17L341.979,8.737l15.136,8.713Z" transform="translate(-175.993 0)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12923" d="M400.948,8.737q0,8.713-10.8,8.713t-10.8-8.713q0-8.736,10.8-8.737t10.8,8.737m-3.084,0q0-3.4-2.3-4.688a11.547,11.547,0,0,0-5.411-.963,11.691,11.691,0,0,0-5.434.952q-2.278,1.289-2.278,4.7t2.3,4.688a11.329,11.329,0,0,0,5.41.976,11.415,11.415,0,0,0,5.411-.976q2.3-1.265,2.3-4.688" transform="translate(-198.593 0)" :fill="color ? color : '#1a1818'"/>
                <path data-name="Path 12924" d="M450.569,12.34q0,3.47-4.3,4.592a33.7,33.7,0,0,1-7.05.518,24.572,24.572,0,0,1-5.989-.542A5.655,5.655,0,0,1,429,13.184l2.952-.82q.723,1.518,3.892,1.9a47.371,47.371,0,0,0,4.748.144q6.893,0,6.893-2.073,0-1.494-3.579-1.916-1.181-.145-4.712-.144-10.219,0-10.219-5.134,0-3.363,4.036-4.507A26.713,26.713,0,0,1,439.771,0a27.066,27.066,0,0,1,6.315.518q3.614.941,4.459,3.9l-2.953.759q-.638-1.567-3.832-1.977a47.623,47.623,0,0,0-4.773-.145q-6.929,0-6.929,2.085,0,1.494,3.471,1.9,1.241.144,4.615.144,10.424,0,10.424,5.146" transform="translate(-224.569 0)" :fill="color ? color : '#1a1818'"/>
            </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Logo",
        props: ["color", "width", "height"],
    }
</script>