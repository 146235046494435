<template>
    <Link v-if="!submit && !anchor && !button && href" :href="href" :class="[outline && 'button-outline', inline && 'button-inline', black && 'button-black']">
        <slot/>
    </Link>
    <button v-if="submit" type="submit" :disabled="$attrs.disable || processing" :class="[outline && 'button-outline', inline && 'button-inline', black && 'button-black']">
        <slot />
    </button>
    <button v-if="button" type="button" :disabled="$attrs.disable || processing" :class="[outline && 'button-outline', inline && 'button-inline', black && 'button-black']">
        <slot/>
    </button>
    <a v-if="anchor" :target="target ? target : '_self'" :href="href" :title="title ? title : ''" :class="[outline && 'button-outline', inline && 'button-inline', black && 'button-black']">
        <slot/>
    </a>
</template>

<script>
    import { LoaderButton } from "../../Icons/Icons";

    export default {
        components: {
            LoaderButton
        },
        props: {
            href: String,
            title: String,
            target: String,
            anchor: Boolean,
            outline: Boolean,
            inline: Boolean,
            submit: Boolean,
            button: Boolean,
            black: Boolean,
            processing: Boolean,
        },
        setup() {
        },
    }
</script>