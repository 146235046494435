<template>
    <div class="grid-categories">
        <h2>Ψάξε αυτοκίνητο ανά</h2>
        <nav class="grid-categories-nav">
            <p @click="setTab(index)" v-for="(category, index) in categories" :class="tab.active === index && 'grid-categories-nav-active'" :key="index">{{ category.name }}</p>
        </nav>
        <div class="grid-categories-main">
            <a v-for="(item, index) in tab.content" :href="item.href" :key="index">{{ item.title }}</a>
        </div>
    </div>
</template>

<script>
    import { reactive } from "vue";
    import { router } from "@inertiajs/vue3";

    export default {
        components: {
        },
        props: {
            filters: Object,
            popularity: Array
        },
        setup(props, { slots, attrs }) {
            const popular = props.popularity.map((item) => { return { title: item, href: `/vehicles?s=${item.replace(/ /g, '%20')}` } });
            const categories = [
                { name: 'Δημοτικότητα', content: popular },
                { name: 'Μάρκα', content: props.filters.make.map((make) => {
                    return { title: make, href: `/vehicles?make[]=${make}` }
                }) },
                { name: 'Χρονολογία', content: [
                    { title: 'έως το 2005', href: '/vehicles?regiyear[to]=2005' },
                    { title: 'έως το 2010', href: '/vehicles?regiyear[to]=2010' },
                    { title: 'έως το 2015', href: '/vehicles?regiyear[to]=2015' },
                    { title: 'έως το 2020', href: '/vehicles?regiyear[to]=2020' },
                    { title: 'από το 2021 και άνω', href: '/vehicles?regiyear[from]=2021' },
                ] },
            ]

            const tab = reactive({
                active: 0,
                content: categories[0].content,
            })

            return { tab, categories }
        },
        emits: ['update_grid'],
        methods: {
            setTab(value) {
                this.tab.active = value;
                this.tab.content = this.categories[value].content;
            },
            goToLink(value) {
                router.visit(value, {
                    onFinish: () => {
                        setTimeout(() => {
                            this.$emit('update_grid');
                        }, 250);
                    }
                });
            }
        }
    }

</script>