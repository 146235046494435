<template>
    <div @click="toggleFavorite" class="car-share-heart">
        <Heart :favorite="favorite" :stroke="true" :color="favorite ? '#FF3B00' : '#000'"  />
    </div>
    <div class="car-share-social">
        <Share @click="activeBox = true" />
        <div class="car-share-social-box animate-fade" v-if="activeBox">
            <a title="Share to Facebook" :href="`https://www.facebook.com/sharer.php?u=${$global.current_url}`" :title="`Share ${car_title} at Facebook`" target="_blank">
                <Facebook width="20" height="20" />
            </a>
            <a title="Share to Twitter" :href="`https://twitter.com/intent/tweet?original_referer=${$global.current_url}&url=${$global.current_url}`" :title="`Tweet ${car_title} at Twitter`" target="_blank">
                <Twitter width="20" height="20" />
            </a>
            <a title="Share to a Friend" :href="`mailto:?subject=&body=${$global.current_url}`" :title="`Share ${car_title} at your friend`" target="_blank">
                <Email width="20" height="20" color="#fff" />
            </a>
        </div>
    </div>
    <div class="car-share-print" @click="print">
        <Print />
    </div>
</template>

<script>
    import { Heart, Share, Print, Facebook, Twitter, Email } from '../../Icons/Icons';
    import { ref } from "vue";

    export default {
        components: {
            Heart, Print, Share,
            Facebook, Twitter, Email
        },
        props: {
            car_id: Number,
            car_title: String,
        },
        setup() {
            const activeBox = ref(false);

            return { activeBox }
        },
        emits: ['add_to_favorite', 'buttons_expand'],
        data() {
            return {
                favorite: false,
            }
        },
        methods: {
            updateFavorite() {
                if (window.localStorage.getItem('favorites_cars')) {
                    const arrayFavorites = JSON.parse(window.localStorage.getItem('favorites_cars')).map((item) => item.id);
                    this.favorite = arrayFavorites.includes(this.car_id) ? true : false;
                }
            },
            toggleFavorite() {
                this.favorite = !this.favorite;
                this.$emit('add_to_favorite');
            },
            closeBox(e) {
                if (e.target.id === 'Icon_feather-share-2' || e.target.classList.value.includes('car-share-social-box')) {
                    this.activeBox = true;
                } else {
                    this.activeBox = false;
                }
            },
            print() {
                setTimeout(() => {
                    window.print();
                }, 500);
                this.$emit('buttons_expand', true);
            }
        },
        mounted() {
            this.updateFavorite();
            document.addEventListener('click', this.closeBox)
        },
        beforeUnmount() {
            document.removeEventListener('click', this.closeBox)
        }
    }
</script>