<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '20.912'" :height="height ? height : '22.305'" viewBox="0 0 20.912 22.305">
        <g transform="translate(-16 0.005)">
            <path data-name="Path 247" d="M35.627,3.64,27.262.155a2.1,2.1,0,0,0-1.608,0L17.29,3.64A2.091,2.091,0,0,0,16,5.572C16,14.22,20.988,20.2,25.65,22.14a2.1,2.1,0,0,0,1.608,0c3.734-1.556,9.655-6.928,9.655-16.568A2.091,2.091,0,0,0,35.627,3.64ZM33.571,8.616l-8.016,8.016a.7.7,0,0,1-.985,0L20.039,12.1a.7.7,0,0,1,0-.985l.985-.985a.7.7,0,0,1,.985,0l3.054,3.054L31.6,6.647a.7.7,0,0,1,.985,0l.985.985a.692.692,0,0,1,0,.985Z" transform="translate(0 0)" :fill="color ? color : '#9ba8b4'" opacity="0.4"/>
            <path data-name="Path 248" d="M117.786,150.2l-8.016,8.016a.7.7,0,0,1-.985,0l-4.531-4.531a.7.7,0,0,1,0-.985l.985-.985a.7.7,0,0,1,.985,0l3.054,3.054,6.539-6.54a.7.7,0,0,1,.985,0l.985.985a.692.692,0,0,1,0,.985Z" transform="translate(-84.215 -141.582)" :fill="color ? color : '#9ba8b4'"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "ShieldCheck",
        props: ["color", "width", "height"],
    }
</script>