<template>
    <section class="message-main" @click="closeMessage()">
        <div :class="`message-text-${$global.message.color}`" class="message-text" v-html="$global.message.text"></div>
    </section>
</template>

<script>
    export default {
        components: {
        },
        props: {

        },
        data() {
            return {
            }
        },
        methods: {
            closeMessage() {
                this.$global.message.open = null;
                this.$global.message.text = null;
                this.$global.message.color = null;
            }
        },
        mounted() {
            setTimeout(() => {
                this.closeMessage();
            }, 5500);
        }
    }
</script>