<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '24'" :height="height ? height : '24'" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="color ? color : 'currentColor'" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
</template>

<script>
    export default {
        name: "Search",
        props: ["color", "width", "height"],
    }
</script>