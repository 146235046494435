<template>
    <section class="verify-page">
        <h1>Ευχαριστούμε για την εγγραφή!</h1>
        <p>
            Πριν ξεκινήσετε, θα μπορούσατε να επαληθεύσετε τη διεύθυνση email σας κάνοντας κλικ στον σύνδεσμο μόλις σας στείλαμε email;
            
            Εάν δεν λάβατε το email, θα σας στείλουμε ευχαρίστως άλλο.
        </p>
        <form class="form-user" @submit.prevent="submitForm">
            <div class="form-user-bottom-button">
                <Button @click="submitForm" inline submit :processing="processing" >Αποστολή ξανά</Button>
            </div>
        </form>
        <form class="form-user" @submit.prevent="logout">
            <div class="form-user-bottom-button">
                <Button @click="logout" outline black submit >Έξοδος</Button>
            </div>
        </form>
    </section>

</template>

<script>
    import Button from "../../Components/Button/Button.vue";
    import { useForm } from "@inertiajs/vue3";

    export default {
        components: {
            Button
        },
        setup() {
            const form = useForm({
                email: '',
            });

            return { form }
        },
        data() {
            return {
                processing: false,
            }
        },
        methods: {
            submitForm() {
                this.processing = true;

                this.form.post(`/email/verification-notification`, {
                    onFinish: () => {
                        this.processing = false;
                        this.$helper.getMessage(this.$global.message, true, 'Ο κωδικός επιβεβαίωσης στάλθηκε στο email σας.', 'green');
                    },
                });
            },
            logout() {
                this.form.post(`/logout`, { });
            },
        }
    }
</script>