<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="color ? color : 'currentcolor'" :width="width ? width : '6'" :height="height ? height : '6'">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
</template>

<script>
    export default {
        name: "Close",
        props: ["color", "width", "height"],
    }
</script>