<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '15.5'" :height="height ? height : '15.5'" viewBox="0 0 15.5 15.5">
        <g transform="translate(-4.25 -4.25)">
            <line data-name="Line 501" y2="14" transform="translate(12 5)" fill="none" :stroke="color ? color : '#eb4e27'" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line data-name="Line 502" x2="14" transform="translate(5 12)" fill="none" :stroke="color ? color : '#eb4e27'" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Plus",
        props: ["color", "width", "height"],
    }
</script>