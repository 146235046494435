<template>
    <section class="forgot-password">
        <h1>Ξέχασα τον κωδικό πρόσβασης</h1>
        <form class="form-user" @submit.prevent="submitForm">
            <div class="form-text">
                <label for="email">Email</label>
                <input required type="email" id="email" v-model="form.email" placeholder="autobesikos@gmail.com"/>
                <InputError :message="$attrs.errors.email" />
            </div>
            <div class="form-user-bottom-button">
                <Button @click="submitForm" inline submit :processing="processing" >Αποστολή</Button>
            </div>
        </form>
    </section>
</template>

<script>
    import Button from "../../Components/Button/Button.vue";
    import { useForm } from "@inertiajs/vue3";
    import InputError from "../../Components/Form/InputError.vue";

    export default {
        components: {
            Button, InputError
        },
        setup() {
            const form = useForm({
                email: '',
            });

            return { form }
        },
        data() {
            return {
                processing: false,
            }
        },
        methods: {
            submitForm() {
                this.processing = true;

                this.form.post(`/forgot-password`, {
                    onSuccess: () => {
                        this.processing = false;
                        this.$helper.getMessage(this.$global.message, true, 'Έχουμε στείλει στο email σας σύνδεσμο, για την επαναφορά του κωδικού πρόσβασής σας.', 'green');
                    },
                    onError: () => {
                        this.processing = false;
                    }
                });
            },
        }
    }
</script>