<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '18.828'" :height="height ? height : '13.414'" viewBox="0 0 18.828 13.414">
        <path id="check" d="M20,6,9,17,4,12" transform="translate(-2.586 -4.586)" fill="none" :stroke="color ? color : '#404040'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </svg>
</template>

<script>
    export default {
        name: "Check",
        props: ["color", "width", "height"],
    }
</script>