<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '16.104'" :height="height ? height : '19.35'" viewBox="0 0 16.104 19.35">
        <g transform="translate(-2.25 -0.25)">
            <path data-name="Path 12935" d="M17.6,8.3c0,5.679-7.3,10.547-7.3,10.547S3,13.982,3,8.3a7.3,7.3,0,1,1,14.6,0Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <circle data-name="Ellipse 243" cx="2.434" cy="2.434" r="2.434" transform="translate(7.868 5.868)" fill="none" :stroke="color ? color : '#000'" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "Pin",
        props: ["color", "width", "height"],
    }
</script>