<template>
    <Head>
        <title>{{ `${page.title} - AutoBesikos `}}</title>
        <meta name="description" :content="page.meta_description">
    </Head>

    <PopupSmall :id="2">
        <div class="warranty-popup">
            <h2>Τι περιλαμβάνει η Μονοετής (1 έτος/20.000 χλμ.) Εγγύηση Μεταχειρισμένου της AutoBesikos</h2>
            <p>
                Η Εγγύηση έχει έναρξη από την ημερομηνία παραλαβής του οχήματος από τον πελάτη και ισχύει μόνο όσο βρίσκεται στην κατοχή του συγκεκριμένου ιδιοκτήτη.
            </p>
            <p>
                Το πρόγραμμα καλύπτει το κόστος των ανταλλακτικών και της εργασίας μόνο για συγκεκριμένα μηχανικά μέρη όπως την επισκευή εσωτερικά του κινητήρα, του κιβωτίου ταχυτήτων και του διαφορικού.
            </p>
            <h2>Δεν περιλαμβάνει:</h2>
            <p>
                Περιφερειακά μέρη του κινητήρα (μίζα, δυναμό, μπεκ καυσίμου, αντλία καυσίμου, κομπρεσέρ A/C, ρουλεμάν και τεντωτήρες ιμάντα, τους εξωτερικούς ιμάντες, όλους τους αισθητήρες, την τουρμπίνα, τον καταλύτη και το φίλτρο μικροσωματιδίων DPF), τα περιφερειακά μέρη του κιβωτίου ταχυτήτων (αντλίες συμπλέκτη, σετ αμπραγιάζ, βολάν) και του διαφορικού (ημιαξόνια, φούσκες ημιαξονίου).
            </p>
            <p>
                Επιπλέον, δεν καλύπτει τις ηλεκτρονικές μονάδες και τις καλωδιώσεις του κινητήρα, του κιβωτίου ταχυτήτων και της καμπίνας, τα υλικά τριβής(τακάκια και δισκόπλακες), το κύκλωμα φρένων (αντλία ABS, αντλίες φρένων και σωληνάκια) και το σύστημα διεύθυνσης (κρεμαργιέρα, ακρόμπαρα, κολώνα τιμονιού).
            </p>
            <h2>Όροι και Προϋποθέσεις:</h2>
            <p>
                Η Εγγύηση αφορά οχήματα στα οποία δεν έχει παρέλθει η 8ετία από την 1η άδεια κυκλοφορίας τους, με έως 80.000 χλμ.
            </p>
            <p>
                Η Εγγύηση έχει ισχύ εφόσον το αυτοκίνητο έχει ασφαλιστεί σε συνεργαζόμενα με την AutoBesikos <a href="/insurance" title="insurance">ασφαλιστικά πρακτορεία</a> καθ’ όλη την διάρκεια της εγγυήσεως.
            </p>
            <p>
                Η Εγγύηση έχει ισχύ εφόσον το αυτοκίνητο συντηρείται σύμφωνα με το πρόγραμμα τακτικών συντηρήσεων με όλα τα προγραμματισμένα προς αντικατάσταση ανταλλακτικά και τις εργασίες που πρέπει να γίνουν ανά 20.000χλμ ή κάθε χρόνο (όποιο επέλθει πρώτο), βάσει του πίνακα προγραμματισμένης συντήρησης σε <a href="/service" title="Service">συνεργεία</a> της AutoBesikos.
            </p>
            <p>
                Δεν αναγνωρίζεται αίτημα επιστροφής ή συμψηφισμός χρημάτων για πραγματοποίηση επισκευής των καλυπτόμενων μερών σε οποιοδήποτε άλλο συνεργείο εκτός της AutoBesikos για το διάστημα που καλύπτεται από την Δωρεάν Εγγύηση.
            </p>
        </div>
    </PopupSmall>

    <div class="single-base">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα</Link>
            <p>/</p>
            <h1>{{ page.title }}</h1>
        </section>
        <section class="single-hero">
            <img v-if="page.media[0]" :src="page.media[0].original_url" :alt="page.media[0].name" width="1920" height="535" />
            <div v-if="this.page.form_position === 'float' || this.page.form_position === 'model' || this.page.form_position === 'bottom'" class="single-hero-text">
                <h2>{{ page.title }}</h2>
            </div>
        </section>
        <div class="single-float">
            <div>
                <Button anchor inline href="#page-form">Επικοινωνία</Button>
            </div>
        </div>    
        <div v-if="this.page.form_position === 'float' || this.page.form_position === 'model' || this.page.form_position === 'bottom'" class="single-main">
            <div :class="this.page.form_position === 'float' || this.page.form_position === 'model' ? 'single-info single-info-float' : 'single-info'">
                <div id="gjs-page-main" v-if="page.gjs_data" v-html="page.gjs_data.html"></div>
                <section v-if="page.form_position === 'float' || page.form_position === 'model'" id="page-form" class="single-form mobile-only">
                    <h2>Φόρμα εκδήλωσης ενδιαφέροντος</h2>
                    <FormSimple v-if="page.form_position === 'float'" :page="page.title" />
                    <FormModel v-if="page.form_position === 'model'" :page="page.title" />
                </section>
            </div>
            <aside v-if="page.form_position === 'float' || page.form_position === 'model'" class="single-aside desktop-only">
                <div class="single-aside-main form-main">
                    <h2>Φόρμα εκδήλωσης ενδιαφέροντος</h2>
                    <FormSimple v-if="page.form_position === 'float'" :page="page.title" />
                    <FormModel v-if="page.form_position === 'model'" :page="page.title" />
                </div>
            </aside>
        </div>
        <div id="gjs-page-main" v-if="this.page.form_position === 'none'" v-html="page.gjs_data.html"></div>
        <section v-if="page.form_position === 'bottom'" class="page-form" id="form-interest">
            <div class="vehicle-heading">
                <h2>Φόρμα εκδήλωσης ενδιαφέροντος</h2>
            </div>
            <div class="vehicle-form-main">
                <FormVehicle interestFor="ασφάλεια" :page="page.title" bottom />
            </div>
        </section>
        <section v-if="$global.current_url.includes('about')" class="about-us-map">
            <h2>Βρείτε μας στον χάρτη</h2>
            <Map />
        </section>
    </div>
</template>

<script>
    import Button from '../Components/Button/Button.vue';
    import FormSimple from '../Components/Form/FormSimple.vue';
    import FormVehicle from '../Components/Form/FormVehicle.vue';
    import FormModel from '../Components/Form/FormModel.vue';
    import Map from '../Components/Map/Map.vue';
    import PopupSmall from '../Components/Popup/PopupSmall.vue';

    export default {
        components: {
            FormSimple, FormVehicle, Button, FormModel, Map, PopupSmall,
        },
        props: {
            page: Object
        },
        methods: {
            stickyNav() {
                const stick = window.pageYOffset || document.documentElement.scrollTop || window.scrollTop;
                const ParentElement = document.querySelector(".single-main");
                const childElement = document.querySelector(".single-aside-main");
                const menuHeight = document.querySelector(".header").offsetHeight;
                
                let parentElementTop = ParentElement.offsetTop;
                let currentHeight = stick + menuHeight + 80;
                let parentElementHeight = ParentElement.offsetTop + ParentElement.offsetHeight;
                let childElementHeight = childElement.offsetHeight;
                
                if ( currentHeight >= parentElementTop && currentHeight < parentElementHeight - childElementHeight ) {
                    childElement.classList.add("single-aside-main-sticky");
                    childElement.classList.remove("single-aside-main-absolute");
                } else if ( currentHeight + 80 >= parentElementHeight - childElementHeight ) {
                    childElement.classList.remove("single-aside-main-sticky");
                    childElement.classList.add("single-aside-main-absolute");
                } else {
                    childElement.classList.remove("single-aside-main-sticky");
                    childElement.classList.remove("single-aside-main-absolute");
                }

                const mobileElement = document.querySelector(".single-float");
                const singleForm = document.querySelector(".single-form");

                if ( stick >= menuHeight && currentHeight <= singleForm.offsetTop - menuHeight - 120 ) {
                    mobileElement.classList.add("single-float-fixed");
                } else {
                    mobileElement.classList.remove("single-float-fixed");
                }
            },
            triggerPopup(value, id) {
                this.$global.popup.open = value;
                this.$global.popup.id = id;
            },
        },
        mounted() {
            // Dark side
            let self = this;

            if (this.page.form_position === 'float' || this.page.form_position === 'model') {
                window.addEventListener("scroll", this.stickyNav);
            }

            if ( document.querySelector('.gjs-cell.disclosure-title') ) {
                const disclosuresTitles = document.getElementsByClassName('disclosure-title');
                const disclosuresText = document.getElementsByClassName('disclosure-text');
                const disclosuresIcon = document.getElementsByClassName('disclosure-icon');

                for (let i = 0; i < disclosuresTitles.length; i++) {
                    disclosuresTitles[i].onclick = function() {
                        disclosuresText[i].classList.toggle('disclosure-text-open');
                        disclosuresIcon[i].classList.toggle('disclosure-icon-open');
                    }
                }
            }
            
            if ( document.querySelector('.single-trigger-small-pop-up') ) {
                document.querySelector('.single-trigger-small-pop-up').onclick = function() { 
                    self.triggerPopup(true, 2);
                }
            }
        },
        beforeUnmount() {
            if (this.page.form_position === 'float' || this.page.form_position === 'model') {
                window.removeEventListener("scroll", this.stickyNav);
            }
        },
    }
</script>