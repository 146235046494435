<template>
    <Head>
        <title>AutoBesikos - Συχνές Ερωτήσεις</title>
        <meta name="description" content="Ανακαλύψτε γρήγορα απαντήσεις σε συχνές ερωτήσεις. Βρείτε ό,τι χρειάζεστε για να ενημερωθείτε και να απολαύσετε την εμπειρία σας!">
    </Head>

    <div class="service-page faq-page">
        <section class="faq-main">
            <h1>Συχνές Ερωτήσεις</h1>
            <div class="faq-main-grid">
                <Disclosure v-for="(qa, key) in qas" :key="`qa-${key}`" :title="qa.question" :text="qa.answer" />
            </div>
        </section>
    </div>
</template>

<script>
    import Disclosure from "../Components/Disclosure/Disclosure.vue";

    export default {
        components: { 
            Disclosure,
        },
        setup() {
            const qas = [
                { question: 'Γιατί να επιλέξω την AutoBesikos;', answer: 'Είμαστε από τις πιο αξιόπιστες εταιρείες στο χώρο του αυτοκινήτου και παραμένουμε ανθρωποκεντρικοί. Η ανώτερη Διοίκηση της εταιρείας παραμένει στην πρώτη γραμμή να ξυπηρετήσει και τον πιο απαιτητικό πελάτη. Εκτός από την αδιαμφισβήτητη αξιοπιστία και την κορυφαία εξυπηρέτηση, έχουμε πραγματική διάθεση να προσθέτουμε αξία στις παρεχόμενες υπηρεσίες προς όλους όσοι μας εμπιστεύονται.' },
                { question: 'Τα αυτοκίνητά σας περνούν από τεχνικό έλεγχο;', answer: 'Όλα τα αυτοκίνητα μας περνούν αυστηρούς τεχνικούς ελέγχους και παραδίδονται σε άριστη κατάσταση. Διαθέτουμε εξειδικευμένο και άρτια οργανωμένο τμήμα after sales για την εξυπηρέτηση σας.' },
                { question: 'Τα χιλιόμετρα είναι πραγματικά;', answer: 'Γνωρίζουμε καλά για την μάστιγα των «πειραγμένων» χιλιομέτρων και διασταυρώνουμε για όλα τα αυτοκίνητα μας την ορθότητα των αναγραφόμενων χιλιομέτρων. Οπότε ΝΑΙ τα χιλιόμετρα που αναγράφουμε στα αυτοκίνητα που βλέπετε στην ιστοσελίδα μας, είναι πραγματικά και σας δίνουμε γραπτή εγγύηση χιλιομέτρων!' },
                { question: 'Μπορώ να ελέγξω το αυτοκίνητο που με ενδιαφέρει σε δικό μου μηχανικό ή συνεργείο;', answer: 'Όλα μας τα αυτοκίνητα περνούν αυστηρούς ελέγχους και παραδίδονται σε άριστη κατάσταση με γραπτή εγγύηση ατρακάριστου και πραγματικών χιλιομέτρων. Εφόσον σας ενδιαφέρει κάποιο από τα μοντέλα του στόλου μας μπορείτε να το ελέγξετε σε οποιοδήποτε μηχανικό ή συνεργείο της επιλογής σας. Το μόνο που έχετε να κάνετε είναι μας πείτε που και πότε θέλετε να πραγματοποιηθεί ο έλεγχος και εμείς θα σας το φέρουμε!' },
                { question: 'Τι εννοείτε όταν λέτε ετοιμοπαράδοτα αυτοκίνητα;', answer: 'Από τη στιγμή που θα προχωρήσετε στην εξόφληση του αυτοκινήτου που σας ενδιαφέρει χρειαζόμαστε το πολύ 5 εργάσιμες μέρες ώστε να ολοκληρώσουμε τις απαραίτητες διαδικασίες και να σας το παραδώσουμε σαν καινούργιο.' },
                { question: 'Τι εννοείτε όταν λέτε εγγύηση ατρακάριστου;', answer: 'Όλα μας τα αυτοκίνητα συνοδεύονται από εγγύηση ατρακάριστου. Όταν λέμε ατρακάριστο εννοούμε ότι το αυτοκίνητο δεν έχει εμπλακεί σε σοβαρό ατύχημα που να έχει επηρεάσει την δομή του. Το τεχνικό μας επιτελείο ελέγχει ενδελεχώς το χώρο του κινητήρα, τα μαρσπιέ και τις κολώνες με εξειδικευμένα εργαλεία, χρησιμοποιούμε τις ειδικές ράμπες για να σηκώσουμε το αυτοκίνητο και να δούμε την κατάσταση του κάτω μέρους. Να είστε βέβαιοι, λοιπόν, ότι το όχημα που επιλέξατε είναι απόλυτα ασφαλές. Στην περίπτωση που το αμάξωμα του αυτοκινήτου έχει κάποιες φθορές, προφανώς και θα προχωρήσουμε στην επισκευή τους ώστε το όχημα να είναι άρτιο και εμφανισιακά.' },
                { question: 'Μπορώ να ανταλλάξω το παλιό μου αυτοκίνητο;', answer: 'Δεχόμαστε ανταλλαγές αφού αξιολογήσουμε το αυτοκίνητο σας. Αν θέλετε μπορείτε να μας καλέσετε για να κλείσουμε ένα ραντεβού να δούμε και να ελέγξουμε το αυτοκίνητό σας. ' },
                { question: 'Αν χρειαστώ service μπορείτε να με εξυπηρετήσετε;', answer: 'Εκτός του ότι είμαστε εξουσιοδοτημένος επισκευαστής Peugeot και Opel, διαθέτουμε υπερσύγχρονο συνεργείο που μπορεί να εξυπηρετήσει όλες τις μάρκες. Απλά κλείστε το ραντεβού σας στο 210 2511800 για να κάνουμε έναν τεχνικό έλεγχο ή να ενημερωθείτε για όλες τις τεχνικές μας υπηρεσίες.' },
                { question: 'Προσφέρετε χρηματοδότηση;', answer: `Συνεργαζόμαστε με τις πιο αξιόπιστες Ελληνικές Τράπεζες και προσφέρουμε χρηματοδότηση προσαρμοσμένη στις δικές σας ανάγκες. Μπορείτε να ενημερωθείτε αναλυτικά <a href="/auto-loan-calculator">εδώ</a>.` },
                { question: 'Δέχεστε και γραμμάτια;', answer: 'Η AutoΒesikos ειδικεύεται στο εμπόριο αυτοκινήτων και την χρηματοδότηση την αφήνει στους ειδικούς που δεν είναι άλλοι από τις πιο αξιόπιστες Τράπεζες της Ελληνικής αγοράς.' },
                { question: 'Έχετε μόνο μεταχειρισμένα αυτοκίνητα;', answer: 'Η εταιρεία μας προσφέρει άριστα και ενδελεχώς ελεγμένα μεταχειρισμένα. Εκτός όμως από τα μεταχειρισμένα, είμαστε εξουσιοδοτημένος διανομέας και επισκευαστής της Peugeot Hellas και της Opel Ελλάς Α.Ε.Ε.' },
                { question: 'Γιατί να σας εμπιστευτώ και να αγοράσω αυτοκίνητο την AutoBesikos;', answer: 'Είμαστε στην αγορά του αυτοκινήτου από το 1975 με συνεχή ανάπτυξη. Το μυστικό της επιτυχίας μας είναι η αξιοπιστία στη συναλλαγή, η ταχύτητα στις διαδικασίες μεταβίβασης, η προσωποποιημένη εξυπηρέτηση και φυσικά το έμπειρο προσωπικό μας.' },
                { question: 'Μπορείτε να μου στείλετε το αυτοκίνητο όπου κι αν μένω;', answer: 'Φυσικά και μπορούμε να σας στείλουμε το αυτοκίνητο που επιλέξατε σε οποιοδήποτε σημείο της Ελλάδας. Εντός Αττικής η παράδοση του οχήματος γίνεται χωρίς χρέωση. Για παραδόσεις εκτός Αττικής υπάρχουν έξοδα αποστολής που υπολογίζονται ανάλογα με την περιοχή και τον τρόπο παράδοσης. Αυτά τα έξοδα επιβαρύνουν τον πελάτη.' },
            ]

            return { qas }
        },
        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
        },
        beforeUnmount() {
        },
    }
</script>