<template>
    <div class="range-slider">
        <Slider v-model="data.value" :step="$attrs.step" :tooltips="true" tooltipPosition="bottom" :min="$attrs.min" :max="$attrs.max" />
        <div class="range-slider-values">
            <p>{{ $attrs.min }}</p>
            <p>{{ $attrs.max }}</p>
        </div>
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<script>
    import Slider from '@vueform/slider'
    import { reactive } from 'vue';

    export default {
        components: {
            Slider,
        },
        props: {
            multi: Boolean,
            car_id: Number,
        },
        setup(props, { slots, attrs }) {
            const data = reactive({
                value: props.multi ? Object.values(attrs.value) : attrs.value,
            });

            return { data };
        },
        emits: ['slider_value'],
        data() {
            return {
                firstLoad: true,
            }
        },
        watch: {
            data: { handler() {
                if ( !this.firstLoad ) {
                    this.emitFunction();
                }
                if ( !this.car_id ) {
                    this.emitFunction();
                }
            }, deep: true },
        },
        methods: {
            emitFunction() {
                if ( !this.multi ) {
                    this.$emit('slider_value', this.data.value);
                } else {
                    this.$emit('slider_value', { from: this.data.value[0] ,to: this.data.value[1] });
                }
            }
        },
        updated() {
            if ( this.firstLoad && this.car_id ) {
                this.data.value = this.multi ? Object.values(this.$attrs.value) : this.$attrs.value;
                this.firstLoad = false;
            }
        }
    }
</script>