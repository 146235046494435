<template>
    <Head>
        <title>{{ car.data.make }} {{ car.data.model }}</title>
        <meta name="description" :content="`To συγκεκριμένο ${car.data.make} ${car.data.model} είναι μοντέλο του ${car.data.regiyear}. Έρχεται με κινητήρα ${car.data.fuel_type} με ${car.data.engine.toLocaleString('el-EL')} κ.ε.κ. που αποδίδει ${car.data.power} άλογα.`">
    </Head>

    <CarImages v-if="!this.noImages" :car="car.data.model" :currentSlide="currentSlide" :slidesPerView="carImages.length >= 5 ? 5 : carImages.length" :images="{exterior: this.car.data.photo_exterior, interior: this.car.data.photo_interior, other: this.car.data.photo_other}"/>
        
    <div class="vehicle">
        <div class="vehicle-mobile-float mobile-only no-print">
            <Button v-if="car.data.sold === 0 && car.data.reserved === 0" :href="`/vehicles/${car.data.id}/checkout`" :title="`Πρόλαβε το ${car.data.model}`" black inline anchor ><span>Online αγορά</span><Cart color="#fff" /></Button>
            <Button href="#form-interest" inline anchor >Επικοινωνία</Button>
        </div>
        
        <div class="vehicle-image no-select">
            <button v-if="!this.noImages" class="vehicle-image-button no-print" @click="triggerPopup(true, 1)">Φωτογραφίες <Images /></button>

            <swiper class="vehicle-image-carousel no-print" @swiper="onSwiper" @slideChange="onSlideChange" :lazy="true" :space-between="0" :loop="true" :modules="modules" :breakpoints="breakpointsCars" :centeredSlides="true" :pagination="{ type: 'bullets', dynamicMainBullets: 2, dynamicBullets: true, clickable: true }" :navigation="true">
                <swiper-slide v-for="(image, index) in carImages" :key="index">
                    <img :src="image" :alt="car.data.model+' - View model'" width="1170" height="780" loading="lazy">
                </swiper-slide>
            </swiper>

            <img v-if="carImages.length > 0" class="print-only" :src="carImages[0]" :alt="car.data.model+' - View model'" width="1170" height="780">
        </div>

        <div class="vehicle-main">
            <div class="vehicle-info">
                <div class="vehicle-head">
                    <div class="vehicle-head-ping mobile-only" v-if="car.data.sold > 0 || car.data.reserved > 0">
                        <p class="vehicle-head-sold" v-if="car.data.sold > 0">
                            Το <strong>{{ car.data.make }} {{ car.data.model }}</strong> έχει πουληθεί. Συμπλήρωσε τα στοιχεία σου στην φόρμα επικοινωνίας ώστε να ενημερωθείς σε περίπτωση που υπάρξει αντίστοιχο μοντέλο.
                        </p>
                        <p class="vehicle-head-reserved" v-else-if="car.data.reserved > 0">
                            Το <strong>{{ car.data.make }} {{ car.data.model }}</strong> έχει δεσμευτεί από πελάτη. Συμπλήρωσε τα στοιχεία σου στην φόρμα επικοινωνίας ώστε να ενημερωθείς σε περίπτωση που δεν προχωρήσει η πώληση.
                        </p>
                    </div>
                    <div class="vehicle-head-price mobile-only">
                        <div class="vehicle-head-price-content">
                            <CarPrice :price="car.data.price" :salePrice="car.data.sale_price" :without_vat="car.data.without_vat"/>
                            <p v-if="car.data.installments">ή με <span>{{ car.data.installments.toLocaleString('el-GR') }}€/</span>μήνα</p>
                        </div>
                        <div class="car-share-buttons no-print" v-if="$global.isMobile">
                            <CarButtons @add_to_favorite="addToFavorite" @buttons_expand="printMore" :car_id="car.data.id" :car_title="`${car.data.make} ${car.data.model}`" />
                        </div>
                    </div>
                    <div class="vehicle-head-info">
                        <div class="vehicle-head-info-content">
                            <p v-if="car.data.regiyear">{{ car.data.regiyear }}</p>
                            <p v-if="car.data.category">{{ car.data.category }}</p>
                            <p v-if="car.data.drive">{{ car.data.drive }}</p>
                            <p v-if="car.data.store">Κατάστημα: {{ car.data.store }}</p>
                            <p v-if="car.data.sold > 0">Πωλήθηκε</p>
                            <p v-else-if="car.data.reserved > 0">Δεσμευμένο</p>
                        </div>
                        <div class="car-share-buttons no-print" v-if="!$global.isMobile">
                            <CarButtons @add_to_favorite="addToFavorite" @buttons_expand="printMore" :car_id="car.data.id" :car_title="`${car.data.make} ${car.data.model}`" />
                        </div>
                    </div>
                    <h1>{{ car.data.make }} {{ car.data.model }} {{ car.data.version ? car.data.version : '' }}</h1>
                    <p class="vehicle-description" v-if="car.data.description" v-html="car.data.description"></p>
                </div>
                <div class="vehicle-details">
                    <h2>ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ</h2>
                    <div class="vehicle-details-content" :class="details && 'vehicle-details-content-open'">
                        <div class="vehicle-details-item" v-for="(item, index) in carDetails" :key="index">
                            <Check v-if="item.status" />
                            <p>{{ item.info }} {{ item.name }}</p>
                        </div>
                    </div>
                    <div class="vehicle-details-more no-print">
                        <button type="button" @click="detailsExpand">{{ !details ? 'Περισσότερα' : 'Λιγότερα' }}</button>
                        <p>{{ !details ? '+' : '-' }}</p>
                    </div>
                </div>

                <div v-if="car.data.installments" class="vehicle-mobile-monthly mobile-only no-print">
                    <form class="form-main">
                        <div class="vehicle-aside-1">
                            <div class="vehicle-aside-1-head" >
                                <p>Δόση αυτοκινήτου από</p>
                                <h3><span>{{ dosi }}€</span>/μήνα</h3>
                            </div>
                            <div class="vehicle-aside-1-buttons">
                                <div>
                                    <Button inline anchor href="#form-interest" >Επικοινωνία</Button>
                                </div>
                            </div>
                        </div>
                        <CarPayment @dosi_value="getDosi" :price="car.data.price" :salePrice="car.data.sale_price" :car="car.data.id" className="vehicle-aside" />
                    </form>
                    <div class="vehicle-mobile-monthly-item">
                        <CarWarranty />
                    </div>
                </div>

                <div class="vehicle-equipment">
                    <h2>ΕΞΟΠΛΙΣΜΟΣ</h2>
                    <div class="vehicle-equipment-content" :class="equipment && 'vehicle-equipment-content-open'">
                        <div class="vehicle-equipment-item" v-for="(item, index) in car.data.features" :key="index">
                            <Check />
                            <p>{{ item }}</p>
                        </div>
                    </div>
                    <div class="vehicle-equipment-more no-print">
                        <button type="button" @click="equipmentExpand">{{ !equipment ? 'Περισσότερα' : 'Λιγότερα' }}</button>
                        <p>{{ !equipment ? '+' : '-' }}</p>
                    </div>
                </div>

                <div class="vehicle-technical">
                    <div class="vehicle-technical-head">
                        <h2>Τεχνικά ελεγμένο</h2>
                        <p>Αρ. Ελέγχου: {{ $helper.padNumber(car.data.id, 5) }}</p>
                    </div>
                    
                    <p>Το συγκεκριμένο {{ car.data.make }} {{ car.data.model }} είναι μοντέλο του {{ car.data.regiyear }} έχει ελεγχθεί με διαδικασίες υψηλών προδιαγραφών, και έχει διαπιστωθεί η καλή κατάσταση του και η δυνατότητα ασφαλούς χρήσης.</p>

                    <div v-if="carTechnical.length > 0" class="vehicle-technical-grid">
                        <p v-for="(item, index) in carTechnical" :key="index">{{ item }}</p>
                    </div>
                </div>

                <div v-if="car.data.video_url" class="vehicle-video">
                    <div class="vehicle-video-head">
                        <h2>Δείτε το αυτοκίνητο σε βίντεο</h2>
                    </div>
                    <iframe title="Youtube Video" width="100%" height="400" :src="('https://www.youtube-nocookie.com/embed/'+car.data.video_url)"></iframe>
                </div>
            </div>
            <aside class="vehicle-aside desktop-only no-print">
                <div class="vehicle-aside-main">
                    <div class="vehicle-aside-item form-main">
                        <form>
                            <div class="vehicle-aside-1">
                                <div class="vehicle-aside-1-head" >
                                    <CarPrice :price="car.data.price" :salePrice="car.data.sale_price" :without_vat="car.data.without_vat"/>
                                    <h3 v-if="car.data.installments">ή με {{ dosi }}€/μήνα</h3>
                                </div>
                                <div class="vehicle-aside-1-buttons">
                                    <div>
                                        <Button outline anchor href="#form-interest" >Επικοινωνία</Button>
                                    </div>
                                    <Button v-if="car.data.sold === 0 && car.data.reserved === 0" :href="`/vehicles/${car.data.id}/checkout`" :title="`Πρόλαβε το ${car.data.model}`" inline anchor >
                                        {{ 'Online αγορά' }}
                                    </Button>
                                    <Button v-else inline button :disable="true">
                                        <template v-if="car.data.sold > 0">
                                            Πωλήθηκε
                                        </template>
                                        <template v-else-if="car.data.reserved > 0">
                                            Δεσμευμένο
                                        </template>
                                    </Button>
                                </div>
                            </div>
                            <p class="vehicle-aside-sold" v-if="car.data.sold > 0">
                                Το <strong>{{ car.data.make }} {{ car.data.model }}</strong> έχει πουληθεί. Συμπλήρωσε τα στοιχεία σου στην φόρμα επικοινωνίας ώστε να ενημερωθείς σε περίπτωση που υπάρξει αντίστοιχο μοντέλο.
                            </p>
                            <p class="vehicle-aside-reserved" v-else-if="car.data.reserved > 0">
                                Το <strong>{{ car.data.make }} {{ car.data.model }}</strong> έχει δεσμευτεί από πελάτη. Συμπλήρωσε τα στοιχεία σου στην φόρμα επικοινωνίας ώστε να ενημερωθείς σε περίπτωση που δεν προχωρήσει η πώληση.
                            </p>
                            <CarPayment v-if="car.data.installments" @dosi_value="getDosi" :price="car.data.price" :salePrice="car.data.sale_price" :car="car.data.id" className="vehicle-aside" />
                        </form>
                    </div>
                    <div class="vehicle-aside-item">
                        <CarWarranty />
                    </div>
                </div>
            </aside>
        </div>
    
        <div class="vehicle-bottom no-print">
            <section v-if="$attrs.latestCars" class="vehicle-new-cars">
                <div class="vehicle-heading">
                    <h2>Είδες πρόσφατα</h2>
                </div>
                <SliderNewCars :cars="$attrs.latestCars.data" noDetails :user="$attrs.auth.user" @favorite_car="updateFavorites" />
            </section>

            <section class="vehicle-form" id="form-interest">
                <div class="vehicle-heading">
                    <h2>Ενδιαφέρεσαι για το {{ car.data.make }} {{ car.data.model }};</h2>
                    <p>Χρειάζεσαι περισσότερες πληροφορίες για το {{ car.data.make }} {{ car.data.model }}; Συμπλήρωσε τα στοιχεία σου στη φόρμα επικοινωνίας και θα επικοινωνήσουμε άμεσα μαζί σου</p>
                </div>
                <div class="vehicle-form-main">
                    <FormVehicle :interestFor="`το ${car.data.make} ${car.data.model}`" :carID="car.data.id" :page="`Αυτοκίνητο - ${car.data.make} ${car.data.model}`" />
                </div>
            </section>
        </div>
    </div>

    <!-- Favorites -->
    <RefreshPageFavorites :user="$attrs.auth.user" :favorites_cars="favorites_cars" :favorites_cars_trigger="favorites_cars_trigger" @postChange="triggerFavorite" />
    <!-- End Favorites -->
</template>

<script>
    import CarPrice from "../Components/Car/CarPrice.vue";
    import Button from "../Components/Button/Button.vue";
    import SliderNewCars from "../Components/Slider/SliderNewCars.vue";
    import Car from "../Components/Car/CarBlock.vue";
    import { Check, Images, Cart } from "../Icons/Icons";
    import CarPayment from "../Components/Car/CarPayment.vue";
    import FormVehicle from "../Components/Form/FormVehicle.vue";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Pagination, Navigation } from "swiper";
    import "swiper/css/navigation";
    import "swiper/css";
    import "swiper/css/pagination";
    import CarImages from "../Components/Car/CarImages.vue";
    import CarButtons from "../Components/Car/CarButtons.vue";
    import { router } from "@inertiajs/vue3";
    import RefreshPageFavorites from "../Components/LocalStorage/RefreshPageFavorites.vue";
    import { computed } from "vue";
    import CarWarranty from "../Components/Car/CarWarranty.vue";

    export default {
        components: {
            Check, Images, Cart,
            Swiper, SwiperSlide,
            Car, Button, SliderNewCars, FormVehicle, CarPrice, CarPayment, CarImages, CarButtons, RefreshPageFavorites, CarWarranty,
        },
        props: {
            car: Object,
        },
        setup(props, { slots, attrs }) {
            const carDetails = [
                { name: props.car.data.make, info: 'Μάρκα:', status: true },
                { name: props.car.data.model, info: 'Μοντέλο:', status: true },
                { name: props.car.data.power, info: 'Ίπποι:', status: true },
                { name: props.car.data.drive, info: 'Σασμάν:', status: true },
                { name: props.car.data.transmission, info: 'Κίνηση:', status: true },
                { name: props.car.data.condition, info: 'Κατάσταση:', status: true },
                { name: props.car.data.regimonth + ' / ' + props.car.data.regiyear, info: 'Χρονολογία:', status: true },
                { name: props.car.data.mileage.toLocaleString('el-EL') + ' χλμ', info: 'Χιλιόμετρα:', status: true },
                { name: props.car.data.fuel_type, info: 'Καύσιμο:', status: true },
                { name: props.car.data.engine.toLocaleString('el-EL'), info: 'Κυβικά:', status: true },
                { name: props.car.data.doors, info: 'Πόρτες:', status: true },
                { name: props.car.data.color, info: 'Χρώμα:', status: true },
            ];

            if (props.car.data.interior_color) { carDetails.push({ name: props.car.data.interior_color, info: 'Χρώμα εσωτερικού:', status: true }) };
            if (props.car.data.interior_trim) { carDetails.push({ name: props.car.data.interior_trim, info: 'Επένδυση σαλονιού:', status: true }) };
            if (props.car.data.pollutant_specifications) { carDetails.push({ name: props.car.data.pollutant_specifications, info: 'Προδιαγραφές Ρύπων:', status: true }) };
            if (props.car.data.carbon_dioxide_emissions) { carDetails.push({ name: props.car.data.carbon_dioxide_emissions, info: 'Εκπομπές CO2 (γρ/χλμ):', status: true }) };
            if (props.car.data.average_consumption) { carDetails.push({ name: props.car.data.average_consumption, info: 'Μέση Κατανάλωση (λίτρα/100χλμ):', status: true }) };
            if (props.car.data.traffic_fees) { carDetails.push({ name: props.car.data.traffic_fees, info: 'Τέλη Κυκλοφορίας (€):', status: true }) };

            if (props.car.data.fuel_type === 'Ηελκτρικό' || props.car.data.fuel_type === 'Υβριδικό Plug-In Βενζίνη') {
                carDetails.push(
                    { name: props.car.data.battery_autonomy, info: 'Αυτονομία μπαταρίας (Χλμ):', status: true },
                    { name: props.car.data.battery_capacity, info: 'Χωρητικότητα μπαταρίας (kWh)', status: true }
                );
            }

            const carTechnical = [
                // '1ο Χέρι', '6 Αερόσακοι', 'ABS', 'ASR', 'Book Service', 'Brake Assist', 'ESP', 'Εγγύηση', 'TCS', 'Ατρακάριστο', 'Εγγύηση'
            ]

            return { carDetails, carTechnical, modules: [Pagination, Navigation]}
        },
        data() {
            return {
                swiper: null,
                details: false,
                equipment: false,
                currentSlide: 0,
                noImages: true,
                dosi: this.car.data.installments ? this.car.data.installments : null,
                activeTab: 1,
                favorites_cars: [],
                favorites_cars_trigger: false,
                orientation: '',
            }
        },
        provide() {
            return {
                // reactive global provider
                updateFavorites: computed(() => this.update_favorites),
            }
        },
        computed: {
            breakpointsCars() {
                return {
                    '992': { slidesPerView: 1.8 },
                    '991': { slidesPerView: 1 },
                }
            },
            carImages() {
                let carsArray = [];

                if ( this.car.data.photo_exterior ) { carsArray.push(...this.car.data.photo_exterior) }
                if ( this.car.data.photo_interior ) { carsArray.push(...this.car.data.photo_interior) }
                if ( this.car.data.photo_other ) { carsArray.push(...this.car.data.photo_other) }

                if ( carsArray.length === 0 ) {
                    this.noImages = false;
                    return [this.$global.no_image, this.$global.no_image, this.$global.no_image, this.$global.no_image];
                } else {
                    this.noImages = false;
                    return carsArray
                }
            }
        },
        methods: {
            detailsExpand() {
                this.details = !this.details;
            },
            equipmentExpand() {
                this.equipment = !this.equipment;
            },
            printMore(value) {
                this.details = value;
                this.equipment = value;
            },
            getDosi(value) {
                this.dosi = value;
            },
            stickyNav() {
                const stick = window.pageYOffset || document.documentElement.scrollTop || window.scrollTop;
                const ParentElement = document.querySelector(".vehicle-main");
                const childElement = document.querySelector(".vehicle-aside-main");
                const menuHeight = document.querySelector(".header").offsetHeight;

                let parentElementTop = ParentElement.offsetTop;
                let currentHeight = stick + menuHeight + 30;
                let parentElementHeight = ParentElement.offsetTop + ParentElement.offsetHeight;
                let childElementHeight = childElement.offsetHeight;
                
                if ( currentHeight >= parentElementTop && currentHeight < parentElementHeight - childElementHeight ) {
                    childElement.classList.add("vehicle-aside-main-sticky");
                    childElement.classList.remove("vehicle-aside-main-absolute");
                } else if ( currentHeight >= parentElementHeight - childElementHeight ) {
                    childElement.classList.remove("vehicle-aside-main-sticky");
                    childElement.classList.add("vehicle-aside-main-absolute");
                } else {
                    childElement.classList.remove("vehicle-aside-main-sticky");
                    childElement.classList.remove("vehicle-aside-main-absolute");
                }
            },
            stickyFloat() {
                const stick = window.pageYOffset || document.documentElement.scrollTop || window.scrollTop;
                const float = document.getElementsByClassName('vehicle-mobile-float')[0];

                const minHeight = 100;
                const maxHeight = document.querySelector('main').offsetHeight - window.innerHeight - document.querySelector('.vehicle-form-main').offsetHeight;

                if ( stick >= minHeight && stick <= maxHeight ) {
                    float.classList.add('vehicle-mobile-float-active');
                } else {
                    float.classList.remove('vehicle-mobile-float-active');
                }
            },
            triggerPopup(value, id) {
                this.$global.popup.open = value;
                this.$global.popup.id = id;
            },
            onSwiper(swiper) {
                this.swiper = swiper;
            },
            onSlideChange(swiper){
                this.currentSlide = swiper.realIndex;
            },
            triggerFavorite(value) {
                // Observe the window changes and re-calculate the active favorites
                this.favorites_cars_trigger = value;
                this.favorites_cars.length = 0;
            },
            addToFavorite() {
                if ( this.$attrs.auth.user) {
                    this.favorites_cars_trigger = !this.favorites_cars_trigger;

                    // Update the array to call axios
                    if ( !this.favorites_cars.includes(this.car.data.id) ) {
                        this.favorites_cars.push(this.car.data.id);
                    } else {
                        const index = this.favorites_cars.indexOf(this.car.data.id);
                        if ( index > -1 ) {
                            this.favorites_cars.splice(index, 1);
                        }
                    }

                    // Get the current local storage - id
                    const arrayFavorites = JSON.parse(window.localStorage.getItem('favorites_cars')).map((item) => item.id);
                    const indexFavorite = arrayFavorites.indexOf(this.car.data.id);

                    // If the item exists in the array
                    if ( indexFavorite > -1 ) {
                        // Remove the item
                        const removeFavorite = JSON.parse(window.localStorage.getItem('favorites_cars')).filter((item) => item.id !== this.car.data.id);
                        window.localStorage.setItem('favorites_cars', JSON.stringify(removeFavorite))
                    } else {
                        // Push the item
                        let arrayStorage = JSON.parse(window.localStorage.getItem('favorites_cars'));
                        arrayStorage.push({ id: this.car.data.id });

                        window.localStorage.setItem('favorites_cars', JSON.stringify(arrayStorage));
                    }
                } else {
                    router.visit('/login', {})
                }
            },
            updateFavorites(id) {
                // Update the array
                if ( !this.favorites_cars.includes(id) ) {
                    this.favorites_cars.push(id);
                } else {
                    const index = this.favorites_cars.indexOf(id);
                    if ( index > -1 ) {
                        this.favorites_cars.splice(index, 1);
                    }
                }

                // Update between tabs - browser
                if ( this.favorites_cars.length > 0 ) {
                    this.favorites_cars_trigger = true;
                } else {
                    this.favorites_cars_trigger = false;
                }
            },
        },
        mounted() {
            window.addEventListener("scroll", this.stickyNav);
            window.addEventListener("scroll", this.stickyFloat);
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.stickyNav);
            window.removeEventListener("scroll", this.stickyFloat);

            if ( this.favorites_cars.length > 0  ) {
                axios.post(`${this.$global.origin}/account/favorites`, { ids: this.favorites_cars }).then((data) => { return data });
            }
        },
    }
</script>