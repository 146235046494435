<template>
    <Head>
        <title>Blog - AutoBesikos</title>
        <meta name="description" content="Διαβάστε τα άρθρα για το πάθος μας που δεν είναι άλλο από το αυτοκίνητο! Μεταχειρισμένα αυτοκίνητα Autobesikos">
    </Head>

    <div class="blog-page">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα</Link>
            <p>/</p>
            <h1>Blog</h1>
        </section>
        <section class="blog-grid">
            <Link class="blog-item" v-for="(item, index) in $attrs.blogs.data" :key="index" :href="`blog/${item.slug}`">
                <img :src="item.image.original_url" :alt="`AutoBesikos - ${item.img_alt}`" width="468" height="312">
                <div>
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.meta_description }}</p>
                    <p>{{ $helper.convertTimeToMonth(item.created_at) }}</p>
                </div>
            </Link>
        </section>
        <section class="blog-pagination">
                <Pagination :pages='$attrs.blogs.meta.links' />
            </section>
    </div>
</template>

<script>
    import Pagination from "../../Components/Pagination/Pagination.vue";

    export default {
        components: { 
            Pagination
        },
        setup() {
            return {  }
        },
        data() {
            return {
            }
        },
        methods: {
        },
    }
</script>