<template>
    <SetFavorites :user="auth.user" />

    <div class="account-page" :class="sidebarIsOpen && 'account-page-open'">
        <UserSidebar @openMenu="SidebarMenu" />
        <div class="account-info">
            <section class="account-info-top">
                <h1>Αλλαγή προσωπικών στοιχείων</h1>
            </section>
            <section class="account-info-main">
                <p>Από εδώ μπορείτε ν’ αλλάξετε τις πληροφορίες του προφίλ</p>
                <form class="form-user account-info-form" @submit.prevent="changeForm">
                    <div class="form-text">
                        <label for="username">Username</label>
                        <input disabled type="text" id="username" v-model="data.username" placeholder="Το username σας"/>
                    </div>
                    <div class="form-text">
                        <label for="name">Όνομα*</label>
                        <input required type="text" id="name" v-model="data.first_name" placeholder="Το όνομα σας"/>
                        <InputError :message="$attrs.errors.first_name"/>
                    </div>
                    <div class="form-text">
                        <label for="lastname">Επώνυμο*</label>
                        <input required type="text" id="lastname" v-model="data.last_name" placeholder="Το επώνυμο σας"/>
                        <InputError :message="$attrs.errors.last_name"/>
                    </div>
                    <div class="form-text">
                        <label for="tel">Τηλέφωνο*</label>
                        <input required type="tel" id="tel" v-model="data.phone" placeholder="Το τηλέφωνο σας"/>
                        <InputError :message="$attrs.errors.phone"/>
                    </div>
                    <div class="form-text">
                        <label for="email">Email</label>
                        <input disabled type="email" id="email" v-model="data.email" placeholder="Το email σας"/>
                        <InputError :message="$attrs.errors.email"/>
                    </div>
                    <div class="form-text">
                        <label>Χώρα</label>
                        <select v-model="data.country">
                            <option value="greece" selected>Ελλάδα</option>
                            <option value="cyprus">Κύπρος</option>
                        </select>
                    </div>
                    <div class="form-text">
                        <label for="town">Πόλη / Περιοχή*</label>
                        <input required type="text" id="town" v-model="data.town" placeholder="Η πόλη / περιοχή σας"/>
                    </div>
                    <div class="form-text">
                        <label for="county">Νομός*</label>
                        <input required type="text" id="county" v-model="data.state" placeholder="Ο νομός σας"/>
                    </div>
                    <div class="form-text">
                        <label for="address">Διεύθυνση*</label>
                        <input required type="text" id="address" v-model="data.address" placeholder="Η διεύθυνση σας"/>
                        <InputError :message="$attrs.errors.address"/>
                    </div>
                    <div class="form-text">
                        <label for="postal">Τ.Κ.*</label>
                        <input required type="number" id="postal" v-model="data.postal_code" placeholder="Ο ταχυδρομικός κώδικας σας"/>
                        <InputError :message="$attrs.errors.postal_code"/>
                    </div>
                    <div class="form-bottom">
                        <div class="form-bottom-button">
                            <Button @click="changeForm" submit inline>Ενημέρωση αλλαγών</Button>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
    import UserSidebar from "../../Components/User/UserSidebar.vue";
    import Button from "../../Components/Button/Button.vue";
    import { useForm } from "@inertiajs/vue3";
    import SetFavorites from "../../Components/LocalStorage/SetFavorites.vue";
    import InputError from "../../Components/Form/InputError.vue";

    export default {
        components: {
            UserSidebar, Button, SetFavorites, InputError
        },
        props: {
            auth: Object
        },
        setup(props, { slots, attrs }) {
            const data = useForm({
                username: props.auth.user.name,
                first_name: '',
                last_name: '',
                phone: '',
                email: props.auth.user.email,
                country: 'greece',
                address: '',
                postal_code: '',
                town: '',
                state: '',
            });

            return { data };
        },
        data() {
            return {
                sidebarIsOpen: false,
            }
        },
        methods: {
            changeForm() {
                this.data.postal_code = this.data.postal_code.toString();

                this.data.patch('/account', {
                    onFinish: () => { 
                        this.data.reset();
                        this.getData();
                    }
                })
            },
            SidebarMenu(value) {
                this.sidebarIsOpen = value;
            },
            getData() {
                if ( this.auth.user ) {
                    if ( this.auth.user.user_detail ) {
                        if ( this.auth.user.user_detail.first_name ) { this.data.first_name = this.auth.user.user_detail.first_name }
                        if ( this.auth.user.user_detail.last_name ) { this.data.last_name = this.auth.user.user_detail.last_name }
                        if ( this.auth.user.user_detail.phone ) { this.data.phone = this.auth.user.user_detail.phone }
                        if ( this.auth.user.user_detail.country ) { this.data.country = this.auth.user.user_detail.country }
                        if ( this.auth.user.user_detail.address ) { this.data.address = this.auth.user.user_detail.address }
                        if ( this.auth.user.user_detail.postal_code ) { this.data.postal_code = this.auth.user.user_detail.postal_code }
                        if ( this.auth.user.user_detail.town ) { this.data.town = this.auth.user.user_detail.town }
                        if ( this.auth.user.user_detail.state ) { this.data.state = this.auth.user.user_detail.state }
                    }
                }
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>