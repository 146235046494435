<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '38.047'" :height="height ? height : '26.751'" viewBox="0 0 38.047 26.751">
        <path d="M52.185,68.186A4.781,4.781,0,0,0,48.821,64.8C45.854,64,33.956,64,33.956,64s-11.9,0-14.865.8a4.781,4.781,0,0,0-3.364,3.386c-.8,2.986-.8,9.217-.8,9.217s0,6.231.8,9.217a4.709,4.709,0,0,0,3.364,3.331c2.967.8,14.865.8,14.865.8s11.9,0,14.865-.8a4.709,4.709,0,0,0,3.364-3.331c.8-2.986.8-9.217.8-9.217S52.98,71.172,52.185,68.186ZM30.065,83.06V71.746L40.009,77.4Z" transform="translate(-14.933 -64)" :fill="color ? color : '#fff'"/>
    </svg>
</template>

<script>
    export default {
        name: "Youtube",
        props: ["color", "width", "height"],
    }
</script>