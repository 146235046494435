<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  :width="width ? width : '147.3'" :height="height ? height : '50'" viewBox="0 0 147.3 50">
        <switch>
            <g i:extraneous="self">
                <path :fill="color ? color : '#E1E5EE'" d="M144.3,50H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h141.3c1.7,0,3,1.3,3,3v44
                    C147.3,48.7,146,50,144.3,50z"/>
                <g>
                    <path style="fill:#1F263A;" d="M29.5,32.8c-1.3,0-2.4-0.7-3.2-2c-0.7,1.2-1.9,2-3.2,2l0,0c-1.4,0-2.6-0.9-3.3-2.3l0,0l-5.4-11.6
                        l6.9-3.2l5,10.6l5-10.6l6.9,3.2l-5.4,11.6C32.1,31.9,30.9,32.8,29.5,32.8L29.5,32.8 M27.9,29.6c0.4,0.8,1,1.2,1.6,1.2l0,0
                        c0.6,0,1.2-0.4,1.6-1.2l4.5-9.8l-3.4-1.6l-4.8,10.3C27.4,28.5,27.9,29.6,27.9,29.6z M21.6,29.6c0.4,0.8,0.9,1.2,1.6,1.2l0,0
                        c0.6,0,1.2-0.4,1.6-1.2l0.5-1.1l-4.9-10.3L17,19.8C17,19.8,21.6,29.6,21.6,29.6z"/>
                    <path style="fill:#1F263A;" d="M46.6,30.6c1,0,1.5-0.8,1.8-1.4l2.5-6.5l-2-0.7l-2.3,6.5L44.2,22l-2,0.7l2.5,6.5
                        C45,29.8,45.5,30.6,46.6,30.6z"/>
                    <path style="fill:#1F263A;" d="M60.7,30.6c1,0,1.5-0.8,1.8-1.4l2.5-6.5L63,22l-2.3,6.5L58.3,22l-2,0.7l2.5,6.5
                        C59.1,29.8,59.6,30.6,60.7,30.6z"/>
                    <path style="fill:#1F263A;" d="M74.3,30.4h-2.1v-0.9c-0.6,0.7-1.4,1.2-2.5,1.2c-2.3,0-4.1-1.9-4.1-4.5s1.8-4.5,4.1-4.5
                        c1.2,0,2,0.5,2.5,1.2V22h2.1C74.3,22,74.3,30.4,74.3,30.4z M67.7,26.1c0,1.4,0.9,2.5,2.3,2.5s2.3-1,2.3-2.5
                        c0-1.4-0.9-2.5-2.3-2.5C68.5,23.7,67.7,24.8,67.7,26.1"/>
                    <path style="fill:#1F263A;" d="M120.6,26.1v0.8h-6.1c0.2,1.2,1.2,1.8,2.3,1.8c0.9,0,1.6-0.3,2.2-0.8l1.3,1.4
                        c-1,0.9-2.2,1.3-3.6,1.3c-2.6,0-4.4-1.8-4.4-4.4s1.7-4.5,4.3-4.5C118.9,21.7,120.6,23.5,120.6,26.1 M114.4,25.4h4
                        c-0.1-1.1-0.9-1.9-2-1.9S114.5,24.4,114.4,25.4"/>
                    <path style="fill:#1F263A;" d="M128.5,23.9h-3.3V27c0,1.1,0.4,1.5,1.4,1.5c0.5,0,1.2-0.3,1.6-0.6l0.8,1.7
                        c-0.7,0.5-1.6,0.9-2.8,0.9c-2.1,0-3.2-1.2-3.2-3.4v-3.3h-1.6v-2h1.6v-2.6h2.1V22h3.3v1.9H128.5z"/>
                    <path style="fill:#1F263A;" d="M90.3,22L88,28.5l-2.1-5.8c-0.2-0.6-0.7-1-1.3-1s-1.1,0.4-1.3,1l-2.1,5.8L78.9,22l-2,0.7l2.6,6.5
                        c0.3,0.6,0.8,1.4,1.8,1.4s1.5-0.8,1.8-1.4l1.5-3.9l1.5,3.9c0.3,0.6,0.8,1.4,1.8,1.4s1.5-0.8,1.8-1.4l2.6-6.5L90.3,22z"/>
                    <path style="fill:#1F263A;" d="M101.5,30.4h-2.1v-0.9c-0.6,0.7-1.4,1.2-2.5,1.2c-2.3,0-4.1-1.9-4.1-4.5s1.8-4.5,4.1-4.5
                        c1.2,0,2,0.5,2.5,1.2V22h2.1C101.5,22,101.5,30.4,101.5,30.4z M94.9,26.1c0,1.4,0.9,2.5,2.3,2.5s2.3-1,2.3-2.5
                        c0-1.4-0.9-2.5-2.3-2.5C95.7,23.7,94.9,24.8,94.9,26.1"/>
                    <rect x="103.7" y="17.8" style="fill:#1F263A;" width="2.1" height="12.6"/>
                    <rect x="108.2" y="17.8" style="fill:#1F263A;" width="2.1" height="12.6"/>
                    <rect x="52.6" y="21.9" style="fill:#1F263A;" width="2.1" height="8.5"/>
                    <path style="fill:#1F263A;" d="M54.9,19.1c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
                        C54.3,17.7,54.9,18.3,54.9,19.1z"/>
                </g>
            </g>
        </switch>
    </svg>
</template>

<script>
    export default {
        name: "VivaWallet",
        props: ["width", "height", "color"],
    }
</script>