<template>
    <div class="slider-reviews">
        <swiper class="slider-reviews-carousel" :slides-per-view="1" :space-between="60" :navigation="true" :loop="true" :modules="modules" :breakpoints="breakpointsReviews">
            <swiper-slide v-for="(review, index) in reviews" :key="index">
                <div class="slider-reviews-item">
                    <div class="slider-reviews-carousel-stars">
                        <Star v-for="(key) in review.star" :key="`${key}-star`" active/>
                        <Star v-if="review !== 5" v-for="(key) in (5 - review.star)" :key="`${key}-not-star`"/>
                    </div>
                    <h3>{{ review.text }}</h3>
                    <h4>{{ review.name }}</h4>
                    <p>{{ review.job }}</p>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { Star } from "../../Icons/Icons";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Navigation } from "swiper";
    import "swiper/css";

    export default {
        components: {
            Swiper, SwiperSlide,
            Star,
        },
        props: {
        },
        setup() {
            const reviews = [
                { text: 'Πολυ ευγενικό και εξυπηρετικό προσωπικό (Λευτέρης & Τζούλιο) Πρόθυμοι να εξηγήσουν λεπτομέρειες και να επιλύσουν απορίες. ', name: 'Αναστάσιος Λ.', job: 'Τοπικός οδηγός', star: 5 },
                { text: 'Πολύ ωραίος χώρος και με εξυπηρέτησε ο κ. Τζουλιανο. Πολύ ευγενικός, εξυπηρετικος και χαμογελαστος. Να τον ζητάτε.', name: 'Θωμάς Κ.', job: 'Τοπικός οδηγός', star: 5 },
                { text: 'Άψογη εξυπηρέτηση, πάντα πρόθυμοι να απαντήσουν σε οποιαδήποτε ερώτηση, πολύ ευγενικοί πωλητές, οι Κωνσταντίνος και Ραφαήλ!! Σίγουρα το συστήνω ανεπιφύλακτα!!', name: 'Βιργινία Π.', job: 'Τοπικός οδηγός', star: 5 },
                { text: 'Επαγγελματίες!! Ο κύριος Χατζηχρήστος πολύ εξυπηρετικός και επαγγελματίας ! Mercedes S450 hybrid σαν καινούργιο !! Μπράβο σας!!', name: 'Σαράντης Κ.', job: 'Τοπικός οδηγός', star: 5 },
                { text: 'Άμεση , γρήγορη κ σωστή εξυπηρέτηση! Ο κ. Τζουλιο πάντα πρόθυμος να βοηθήσει τόσο πριν όσο κ μετά την αγορά του αυτοκινήτου που έχουμε κάνει!', name: 'Ηλίας Σ.', job: 'Τοπικός οδηγός', star: 5 },
                { text: 'Καλημέρα! Πριν μια εβδομάδα έκανα το ωραιότερο δώρο στον εαυτό μου! Toyota Aygo!!! Τα ανέλαβε όλα ο κύριος Τζούλιο. Σωστός, ειλικρινής, προσιτός, με θετική σκέψη, πάντα! Είμαι στον χώρο των πωλήσεων 27 χρόνια και πιστέψτε με, μπορώ να αναγνωρίσω τους επαγγελματίες.', name: 'Αγγέλα Μ.', job: 'Τοπικός οδηγός', star: 5 },
            ]
            return { reviews, modules: [Navigation] }
        },
        computed: {
            breakpointsReviews() {
                return {
                    '1200': { slidesPerView: 3 },
                    '800': { slidesPerView: 2 },
                    '400': { slidesPerView: 1 },
                }
            },
        },
    }
</script>